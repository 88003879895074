import moment from 'moment';

const MINUTES_TO_CHANGE = 15;

export const add15Minutes = (
  hours: string,
  minutes: string,
  timePeriod: string,
) => {
  const dateString = `${hours}:${minutes} ${timePeriod}`;

  if (!moment(dateString, 'LT').isValid())
    return { hours, minutes, timePeriod };

  const newDate = moment(dateString, 'LT').add(MINUTES_TO_CHANGE, 'minutes');

  const newTimePeriod = newDate.format('LT').split(' ')[1];
  const newHours = newDate.format('LT').split(':')[0];
  const newMinutes = newDate.format('LT').split(' ')[0].split(':')[1];

  return { hours: newHours, minutes: newMinutes, timePeriod: newTimePeriod };
};

export const substract15Minutes = (
  hours: string,
  minutes: string,
  timePeriod: string,
) => {
  const dateString = `${hours}:${minutes} ${timePeriod}`;

  if (!moment(dateString, 'LT').isValid())
    return { hours, minutes, timePeriod };

  const newDate = moment(dateString, 'LT').subtract(
    MINUTES_TO_CHANGE,
    'minutes',
  );

  const newTimePeriod = newDate.format('LT').split(' ')[1];
  const newHours = newDate.format('LT').split(':')[0];
  const newMinutes = newDate.format('LT').split(' ')[0].split(':')[1];

  return { hours: newHours, minutes: newMinutes, timePeriod: newTimePeriod };
};
