import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as Chevrone } from 'assets/icons/chevron-up-fix.svg';
import { TimeSlotType } from 'hooks/useSignUpProviderSchedule.';
import { add15Minutes, substract15Minutes } from '../utils/timeUtils';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';

interface ITimeSlotPicker {
  onRemoveHandler: () => void;
  slot: TimeSlotType;
  onSlotChangeHandler: (param: keyof TimeSlotType, value: string) => void;
}

export const TimeSlotPicker = ({
  onRemoveHandler,
  slot,
  onSlotChangeHandler,
}: ITimeSlotPicker) => {
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SIGN_UP_PROVIDER_MODULE,
  });

  const onIncrement = (
    hours: string,
    minutes: string,
    timePeriod: string,
    isPeriodStart: boolean,
  ) => {
    const {
      hours: newHours,
      minutes: newMinutes,
      timePeriod: newTimePeriod,
    } = add15Minutes(hours, minutes, timePeriod);

    if (isPeriodStart) {
      onSlotChangeHandler('fromHours', newHours);
      onSlotChangeHandler('fromMinutes', newMinutes);
      onSlotChangeHandler('fromTimePeriod', newTimePeriod);
    } else {
      onSlotChangeHandler('toHours', newHours);
      onSlotChangeHandler('toMinutes', newMinutes);
      onSlotChangeHandler('toTimePeriod', newTimePeriod);
    }
  };

  const onDecrement = (
    hours: string,
    minutes: string,
    timePeriod: string,
    isPeriodStart: boolean,
  ) => {
    const {
      hours: newHours,
      minutes: newMinutes,
      timePeriod: newTimePeriod,
    } = substract15Minutes(hours, minutes, timePeriod);

    if (isPeriodStart) {
      onSlotChangeHandler('fromHours', newHours);
      onSlotChangeHandler('fromMinutes', newMinutes);
      onSlotChangeHandler('fromTimePeriod', newTimePeriod);
    } else {
      onSlotChangeHandler('toHours', newHours);
      onSlotChangeHandler('toMinutes', newMinutes);
      onSlotChangeHandler('toTimePeriod', newTimePeriod);
    }
  };

  return (
    <div className="flex items-center gap-2.5">
      <p className="text-base font-semibold text-btn-black">
        {locale?.schedule?.from}
      </p>
      <div className="w-[157px] h-[46px] border border-med-gray-3 rounded flex items-center gap-[5px] pl-2.5">
        <input
          className="w-8 h-8 pl-1 focus-visible:outline-none"
          value={slot.fromHours}
          onChange={(e) =>
            onSlotChangeHandler('fromHours', e.currentTarget.value)
          }
          maxLength={2}
        />
        <span>:</span>
        <input
          className="w-8 h-8 pl-1 focus-visible:outline-none"
          value={slot.fromMinutes}
          onChange={(e) =>
            onSlotChangeHandler('fromMinutes', e.currentTarget.value)
          }
          maxLength={2}
        />
        <input
          className="w-8 h-8 pl-1 focus-visible:outline-none"
          value={slot.fromTimePeriod}
          maxLength={2}
          onChange={(e) =>
            onSlotChangeHandler('fromTimePeriod', e.currentTarget.value)
          }
        />
        <div className="h-[46px] border-l border-l-med-gray-3 flex flex-col justify-center">
          <button
            onClick={() =>
              onIncrement(
                slot.fromHours,
                slot.fromMinutes,
                slot.fromTimePeriod,
                true,
              )
            }
            className="w-[25px] h-5 border-b border-b-med-gray-3 flex items-center justify-center"
          >
            <Chevrone className="text-med-gray-3" />
          </button>
          <button
            onClick={() =>
              onDecrement(
                slot.fromHours,
                slot.fromMinutes,
                slot.fromTimePeriod,
                true,
              )
            }
            className="w-[25px] h-5 flex items-center justify-center"
          >
            <Chevrone className="text-med-gray-3 rotate-180" />
          </button>
        </div>
      </div>
      <p className="text-base font-semibold text-btn-black">
        {locale?.schedule?.to}
      </p>
      <div className="w-[157px] h-[46px] border border-med-gray-3 rounded flex items-center gap-[5px] pl-2.5">
        <input
          className="w-8 h-8 pl-1 focus-visible:outline-none"
          value={slot.toHours}
          maxLength={2}
          onChange={(e) =>
            onSlotChangeHandler('toHours', e.currentTarget.value)
          }
        />
        <span>:</span>
        <input
          className="w-8 h-8 pl-1 focus-visible:outline-none"
          value={slot.toMinutes}
          maxLength={2}
          onChange={(e) =>
            onSlotChangeHandler('toMinutes', e.currentTarget.value)
          }
        />
        <input
          className="w-8 h-8 pl-1 focus-visible:outline-none"
          value={slot.toTimePeriod}
          maxLength={2}
          onChange={(e) =>
            onSlotChangeHandler('toTimePeriod', e.currentTarget.value)
          }
        />
        <div className="h-[46px] border-l border-l-med-gray-3 flex flex-col justify-center">
          <button
            onClick={() =>
              onIncrement(
                slot.toHours,
                slot.toMinutes,
                slot.toTimePeriod,
                false,
              )
            }
            className="w-[25px] h-5 border-b border-b-med-gray-3 flex items-center justify-center"
          >
            <Chevrone className="text-med-gray-3" />
          </button>
          <button
            onClick={() =>
              onDecrement(
                slot.toHours,
                slot.toMinutes,
                slot.toTimePeriod,
                false,
              )
            }
            className="w-[25px] h-5 flex items-center justify-center"
          >
            <Chevrone className="text-med-gray-3 rotate-180" />
          </button>
        </div>
      </div>
      <button
        onClick={onRemoveHandler}
        className="w-6 text-h2 text-clc-blue font-bold"
      >
        <MinusIcon />
      </button>
    </div>
  );
};
