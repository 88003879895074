export const passwordPattern =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*])(.{8,64})$';

export const phoneNumberPattern = '\\(\\d{3}\\) \\d{3}-\\d{4}';
export const anyNumbersAndLettersRegex = /^[a-zA-Z0-9]*$/;
export const alphanumericsDashAndSpace = /^[\w0-9\-\s]*$/;
export const zipcodePattern = '^\\d{5}(?:-\\d{4})?$';
export const onlyDigitsPattern = /[^\d]/g;

export const trackingNumberFormat =
  /^(9400\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9205\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9407\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9303\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|82\s?\d{3}\s?\d{3}\s?\d{1,3}|EC\s?\d{3}\s?\d{3}\s?\d{3}(\s?US)?|9270\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|EA\s?\d{3}\s?\d{3}\s?\d{3}(\s?US)?|CP\s?\d{3}\s?\d{3}\s?\d{3}(\s?US)?|9208\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9202\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|9218\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{1,4}|)$/;

export const validCOSkinSwabCodesRegex =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  /(?<CO>^C(?:O|0)(?:-|\s)?\d{7}(?:20[0-9]|2\d{2}|3\d{2}|399))/;
export const validCOVitaminSwabCodesRegex =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  /(?<CO>^C(?:O|0)(?:-|\s)?\d{7}(?:00[0-9]|0\d{2}|1\d{2}|199))/;
export const exact6DigitsPattern = '^\\d{6}$';
export const exact10DigitsPattern = '^\\d{10}$';
export const min7OnlyDigitsPattern = '^\\d{7,}$';
export const emailPattern =
  '^[a-zA-Z0-9._-]+(\\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';

export const TEST_SKIN_VALIDATION_REGEX =
  '(?<CO>^CO[ -]?\\d{10}$)|(?<CS>^CS[ -]?\\d{10}$)|(?<C0>^C0[ -]?\\d{10}$)';

export const TEST_VITAMIN_VALIDATION_REGEX =
  '(?<CO>^CO[ -]?\\d{10}$)|(?<CV>^CV[ -]?\\d{10}$)|(?<C0>^C0[ -]?\\d{10}$)';

export const REMOVE_WHITE_SPACES_REGEX = '[-\\s]';
export const SWABCODE_PREFIX_REGEX = '^(CO|CS|C0|CV)-?(\\d+)$';
