import { ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as CheckActive } from '../../../assets/icons/check-active.svg';
import { ReactComponent as EditBlue } from '../../../assets/icons/edit-blue.svg';
import { DynamicQuestionnaireSidebarListProps } from '../interfaces/dynamicQuestionnaireSidebar.interface';
import { InBetweenOptionLine } from './inBetweenOptionLine';
import { SavedLabel } from 'components/SavedLabel/SavedLabel';

export const DynamicQuestionnaireSidebarList = ({
  items,
  currentItem,
  isSidebarBlocked,
  onClick,
}: DynamicQuestionnaireSidebarListProps) => {
  const generateItemNumber = (value: number) => {
    const shiftedValue = value + 1;

    if (shiftedValue < 10) {
      return `0${shiftedValue}`;
    }
    return shiftedValue;
  };

  return (
    <>
      {items.map((item, key) => (
        <ul
          dir="ltr"
          id={item.name}
          onClick={isSidebarBlocked ? () => null : () => onClick(item.name)}
          className={`${currentItem === item.name ? 'desktop:bg-white' : ''} ${
            isSidebarBlocked ? 'cursor-not-allowed' : 'cursor-pointer'
          } ${
            items.length !== key + 1 ? 'w-full' : ''
          } flex justify-between items-center desktop:pl-5 desktop:pr-5 rounded-s-[10px] bg-transparent p-0`}
          key={item.name}
        >
          <div className="flex items-center">
            <span
              id={`circle-${key}${item.complete ? '-complete' : ''}`}
              className={`${
                item.isSaved ? '!bg-white' : ''
              } circle text-link flex items-center justify-center font-bold w-[25px] h-[25px] rounded-[15px] pt-[1px] shrink-0`}
            >
              {item.complete ? (
                <CheckActive
                  width="10px"
                  color={item.isSaved ? '#2B43DA' : '#fff'}
                />
              ) : (
                generateItemNumber(key)
              )}
            </span>
            <li
              className={`p-5 desktop:flex hidden text-base text-btn-black font-semibold ${
                currentItem === item.name ? 'text-btn-black' : 'text-med-gray-3'
              }`}
            >
              {item.name}
            </li>
          </div>
          <InBetweenOptionLine
            optionsList={items}
            currentMapIndex={key}
            currentItem={item}
          />
          <div
            className={`${
              item.isSaved || currentItem === item.name
                ? 'desktop:flex'
                : 'hidden'
            } hidden`}
          >
            {item.isSaved ? (
              <div className="flex gap-2.5 items-center">
                <SavedLabel />
                <EditBlue />
              </div>
            ) : (
              <ChevronRight style={{ width: '10px', height: '15px' }} />
            )}
          </div>
        </ul>
      ))}
    </>
  );
};
