import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const usePdfDownload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const patientId = params?.patientId ?? '';

  const downloadPdf = (reportId: string, reportName?: string) => {
    navigate(`${location.pathname}?download-warning=true`, {
      state: { reportId: reportId, reportName, patientId },
    });
  };

  const downloadPdfWithURI = (uri: string, reportName?: string) => {
    navigate(`${location.pathname}?download-warning=true`, {
      state: { reportURI: uri, reportName },
    });
  };

  return {
    downloadPdf,
    downloadPdfWithURI,
  };
};
