export const FIND_A_PROVIDER = 'find-a-provider';
export const APPOINTMENT_DETAILS = 'appointment-details';
export const SYMPTOMS = 'symptoms';
export const PLACE_SYMPTOMS = 'place-symptoms';
export const PAYMENTS = 'payments';

export type AppointmentStoreKeys = keyof typeof APPOINTMENT_STORE_VALUES;

export const APPOINTMENT_STORE_VALUES = {
  selectedProvider: FIND_A_PROVIDER,
  selectedAppointment: APPOINTMENT_DETAILS,
  selectedSymptoms: SYMPTOMS,
  selectedPlacement: PLACE_SYMPTOMS,
};

export const APPOINTMENT_STORAGE = 'appointment:new';
