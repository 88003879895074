import React from 'react';
import { Tooltip as ReactTooltip, ITooltip } from 'react-tooltip';

const CustomTooltip: React.FC<ITooltip> = ({
  place,
  className,
  noArrow,
  id,
  content,
  anchorSelect,
}) => {
  return (
    <ReactTooltip
      id={id}
      anchorSelect={anchorSelect}
      place={place}
      content={content}
      noArrow={noArrow}
      className={className}
    />
  );
};

export default CustomTooltip;
