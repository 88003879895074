import { ReactElement, ReactNode } from 'react';

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right-gray.svg';

interface ISignUpRoleButton {
  children: ReactNode;
  icon: ReactElement;
  onClick: () => void;
}

const SignUpRoleButton = ({ children, icon, onClick }: ISignUpRoleButton) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center bg-white w-full rounded p-5"
    >
      <div className="mr-[10px] grow-0 shrink-0">{icon}</div>
      <div className="grow text-left text-base font-semibold text-btn-black">
        {children}
      </div>
      <ChevronRight className="grow-0 shrink-0" />
    </button>
  );
};

export default SignUpRoleButton;
