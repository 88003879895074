import {
  allowCharactersWithMaxLength,
  allowOnlyLettersWithAllowedCharacters,
  allowOnlyNumbers,
  allowOnlyNumbersAndMaxLengthForZipCodes,
  allowOnlyStringWithNoSpaces,
} from 'utilities/functions';
import { SectionPropertiesToCheck } from '../interfaces/signUpProviderResponse.interface';
import { ALLOWED_CHARACTERS_CITY_FIELD } from 'utilities/constants';

export const SPECIFIC_UNAUTHENTICATED_QUESTION_IDS = {
  GENDER_QUESTION: 9,
  GENDER_OTHER_QUESTION: 10,
  LANGUAGES: 11,
  CREDENTIALS_TITLE: 13,
  CREDENTIALS_STATE: 14,
  CREDENTIALS_CERTIFICATIONS: 15,
  EDUCATION_SCHOOL: 16,
  EDUCATION_RESIDENCY: 17,
  EDUCATION_UNDERGRADUATE: 18,
  OFFICE_NAME: 19,
  CONTACT_INFO_OFFICE_ZIP_CODE: 28,
  OFFICE_INFORMATION_COUNTRY: 24,
  OFFICE_INFORMATION_STATE: 25,
  OFFICE_INFORMATION_CITY: 26,
  PERSONAL_INFORMATION_ADDRESS_1: 29,
  PERSONAL_INFORMATION_ADDRESS_2: 30,
  PERSONAL_INFORMATION_COUNTRY: 31,
  PERSONAL_INFORMATION_STATE: 32,
  PERSONAL_INFORMATION_CITY: 33,
  PERSONAL_INFORMATION_ZIP_CODE: 35,
  MEDICAL_ROLE: 36,
  YEARS_OF_PRACTICE: 37,
  EDUCATION_DYNAMIC_LIST: 99,
};

export const SPECIFIC_UNAUTHENTICATED_QUESTION_RESPONSE = {
  OTHER: 'Other',
};

export const SPECIFIC_UNAUTHENTICATED_SECTION = {
  PERSONAL_INFO: 'Personal Info',
  ACCOUNT_INFO: 'Account Info',
  CRENDENTIALS_AND_CERTIFICATIONS: 'Credentials & certifications',
  CONTACT_INFO: 'Contact Info',
  TWO_FA: 'Two-factor authentication',
  SCHEDULE: 'Schedule & availability',
};

export const initialSections: SectionPropertiesToCheck[] = [
  {
    name: 'Account Info',
    propertiesToCheck: ['firstname', 'lastname', 'nationalProviderIdentifier'],
  },
  {
    name: 'Personal Info',
    propertiesToCheck: ['gender', 'languagesOfCommunication', 'about'],
  },
  {
    name: 'Two-factor authentication',
    propertiesToCheck: ['phone'],
  },
  {
    name: 'Credentials & certifications',
    propertiesToCheck: [],
  },
  {
    name: 'Contact Info',
    propertiesToCheck: [
      'office_information_office_name',
      'office_information_address_1',
      'office_information_email',
      'office_information_phone',
      'office_information_zip_code',
      'address_information_address_1',
      'address_information_zip_code',
    ],
  },
  {
    name: 'Schedule & availability',
    propertiesToCheck: [],
  },
];

// SignUpProvider - Skip rendering ids in order to add a dynamic education section at the end of the current section
export const SignUpProviderSkipRenderingIds = [
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_SCHOOL,
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_RESIDENCY,
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.EDUCATION_UNDERGRADUATE,
];

export const staticIdsForAddressContactInfo = [
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_ADDRESS_1,
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_ADDRESS_2,
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_COUNTRY,
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_STATE,
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_CITY,
  SPECIFIC_UNAUTHENTICATED_QUESTION_IDS.PERSONAL_INFORMATION_ZIP_CODE,
];

export const OTHER = 'Other';

export const signUpValidationFunctionsRules = [
  {
    callback: allowOnlyLettersWithAllowedCharacters,
    properties: ['firstname', 'lastname', 'genderDescribe'],
    maxLength: 150,
  },
  {
    callback: allowOnlyLettersWithAllowedCharacters,
    properties: ['office_information_office_name'],
    maxLength: 150,
  },
  {
    callback: allowOnlyNumbers,
    properties: ['nationalProviderIdentifier'],
    maxLength: 10,
  },
  {
    callback: allowOnlyStringWithNoSpaces,
    properties: ['email', 'office_information_email'],
    maxLength: 350,
  },
  {
    callback: allowOnlyStringWithNoSpaces,
    properties: ['password', 'confirmPassword'],
    maxLength: 64,
  },
  {
    callback: allowOnlyNumbers,
    properties: ['phone', 'office_information_phone', 'years_of_practice'],
  },
  {
    callback: allowCharactersWithMaxLength,
    properties: [
      'office_information_address_1',
      'office_information_address_2',
      'address_information_address_1',
      'address_information_address_2',
    ],
  },
  {
    callback: allowOnlyNumbersAndMaxLengthForZipCodes,
    properties: ['office_information_zip_code', 'address_information_zip_code'],
  },
];

export const hormoneValidationFunctionsRules = [
  {
    callback: allowOnlyLettersWithAllowedCharacters,
    properties: ['firstname', 'lastname'],
  },
  {
    callback: allowOnlyNumbers,
    properties: [
      'height5',
      'height8',
      'weight',
      'waist',
      'fast_time',
      'blood_sample_time13',
    ],
  },
];

export const allowOnlyCharactersInCityField = (
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  if (!ALLOWED_CHARACTERS_CITY_FIELD.test(event.key)) {
    event.preventDefault();
  }
};

export const HORMONE_QUESTIONNAIRE_SECTIONS = {
  INDIVIDUAL_INFORMATION: 'Individual information',
  MENSTRUAL_STATUS: 'Menstrual status',
  SYMPTOMS: 'Symptoms',
  HORMONE_AND_MEDICATIONS: 'Hormone/medication use',
};

export const medicationIdsFromHormoneQuestionnaire = [
  160, 161, 162, 163, 164, 165, 166,
];

export const HORMONE_MEDICATION_MAX_LENGTH = 11;

export const HORMONE_QUESTIONNAIRE_SPECIFIC_IDS = {
  FIRST_NAME: 1,
  LAST_NAME: 2,
  DOB: 3,
  GENDER: 4,
  HEIGHT_FIRST_FIELD: 5,
  HEIGHT_SECOND_FIELD: 8,
  WEIGHT: 9,
  WAIST: 10,
  FAST_FIELD: 11,
  BLOOD_SAMPLE: 12,
  FEMALE_SYMPTOMS_FIRST_QUESTION: 93,
  FEMALE_SYMPTOMS_LAST_QUESTION: 159,
  MALE_SYMPTOMS_FIRST_QUESTION: 93,
  MALE_SYMPTOMS_LAST_QUESTION: 159,
  MALE_SYMPTOMS_SKIP_IDS: [100, 106, 107, 108, 141, 155, 156],
  MEDICATION_TYPE_QUESTION: 160,
  MEDICATION_USAGE_TIME_QUESTION: 166,
  ENABLE_SHARE_DATA_ID: 15350,
};

export const MEDICATIONS_IDS = {
  MEDICATION: 75,
  DOSAGE: 79,
  FRECUENCY: 81,
  MEDICATIONS_DYNAMIC_LIST: 90001,
  TABLE_MEDICATIONS: 999,
};
