import { ReactNode, useContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../firebase/interfaces';
import { ProviderPendingApproval } from 'app/my-account/pages/provider-pending-approval/ProviderPendingApproval';
import PageLayout from 'layout/PageLayout';
import { AUTH_LOGIN, MY_PROFILE } from 'utilities/routes';
import { Buffer } from 'buffer';
import { MyProfilePage } from 'app/my-account/pages/my-profile/MyProfile';
import NetworkRequiredRoutes from 'components/networkRequiredRoutes';
import RoleRequiredRoute from 'components/RoleRequiredRoute';

interface Props {
  children: ReactNode;
}

export const AuthenticatedRoute = ({ children }: Props) => {
  const location = useLocation();
  const { isLoggedIn, user } = useContext(AuthContext);
  const decodedRedirect = `${location.pathname ?? ''}${location.search ?? ''}`;

  const redirect =
    decodedRedirect &&
    encodeURIComponent(Buffer.from(decodedRedirect).toString('base64'));

  const getContent = () => {
    if (!isLoggedIn) {
      return (
        <Navigate
          to={`${AUTH_LOGIN}${redirect ? `?redirect=${redirect}` : ''}`}
        />
      );
    }

    if (user?.role !== Roles.PROVIDER || user?.providerEnabled) {
      return <>{children}</>;
    }

    return (
      <Routes>
        <Route
          path={MY_PROFILE}
          element={
            <PageLayout>
              <NetworkRequiredRoutes>
                <MyProfilePage />
              </NetworkRequiredRoutes>
            </PageLayout>
          }
        />
        <Route
          path="*"
          element={
            <PageLayout>
              <NetworkRequiredRoutes>
                <RoleRequiredRoute allowedRoles={[Roles.PROVIDER]}>
                  <ProviderPendingApproval />
                </RoleRequiredRoute>
              </NetworkRequiredRoutes>
            </PageLayout>
          }
        />
      </Routes>
    );
  };

  return <>{getContent()}</>;
};
