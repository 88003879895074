import { Roles } from '../../../firebase/interfaces';
import {
  MILLISECONDS_IN_SEC,
  SECONDS_IN_MIN,
} from '../../../utilities/constants/time';

export const TAB_INACTIVE = -99;
const MILLISECONDS_IN_MIN = MILLISECONDS_IN_SEC * SECONDS_IN_MIN;
export const GAP_MINUTES = 3;
export const RolesTimeStamp = {
  [Roles.PROVIDER]: GAP_MINUTES * MILLISECONDS_IN_MIN,
  [Roles.SUPERADMIN]: 0,
  [Roles.ADMIN]: 0,
  [Roles.ADMIN_OBFUSCATED]: 0,
  [Roles.PATIENT]: 0,
};

export const TIME_TO_NOTIFICATION_FROM_ENDING =
  GAP_MINUTES * MILLISECONDS_IN_MIN - 1;
