import { AuthContext } from 'auth/context/AuthContext';
import { useContext, useState } from 'react';
import useFhirProviderDataLoader from './useFhirProviderDataLoader';
import {
  useCreateCalendarAndDefineRegularHoursMutation,
  useUpdateCalendarRegularHoursMutation,
} from 'graphql/generated/hasura';
import { processProviderSchedule } from 'components/dynamicQuestionnaire/utils/utils';

export type TimeSlotType = {
  fromHours: string;
  fromMinutes: string;
  fromTimePeriod: string;
  toHours: string;
  toMinutes: string;
  toTimePeriod: string;
};

const INITIAL_SLOTS_STATE = Array(7).fill([]);

export const useSignUpProviderSchedule = () => {
  const { user: loggedUser } = useContext(AuthContext);

  const { providerProfileData } = useFhirProviderDataLoader(
    loggedUser,
    loggedUser?.email,
  );

  const [schedule, setSchedule] =
    useState<Array<Array<TimeSlotType>>>(INITIAL_SLOTS_STATE);

  const [createCalendarAndDefineRegularHoursMutation] =
    useCreateCalendarAndDefineRegularHoursMutation();
  const [updateCalendarRegularHoursMutation] =
    useUpdateCalendarRegularHoursMutation();

  const onSubmitSchedule = () => {
    const createBody = {
      ...processProviderSchedule(schedule),
      email: loggedUser?.email,
    };
    const editBody = {
      ...processProviderSchedule(schedule),
      calendarId: providerProfileData?.acuityCalendarId,
    };

    if (providerProfileData?.acuityCalendarId) {
      updateCalendarRegularHoursMutation({
        variables: {
          input: editBody,
        },
      });

      return;
    }

    createCalendarAndDefineRegularHoursMutation({
      variables: {
        input: createBody,
      },
      refetchQueries: ['GetFHIRProviderbyEmail'],
    });
  };

  return { schedule, setSchedule, onSubmitSchedule };
};
