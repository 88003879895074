import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { downloadPdf } from 'utilities/functions';
import { useGetFileFromStorage } from 'hooks/useGetFileFromStorage';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { useGetPage } from 'hooks/useGetPage';
import Modal from 'components/modal/modalComponent';
import IconComponent from 'components/iconComponent';

const ScanActionsDownloadWarning = () => {
  const location = useLocation();
  const reportId = location.state?.reportId;
  const reportURI = location.state?.reportURI;
  const reportName = location.state?.reportName || 'report.pdf';
  const patientId = location.state?.patientId;
  const navigate = useNavigate();
  const { fetchFileFromStorage } = useGetFileFromStorage();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.DOWNLOAD_REPORT,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL, genericActionsIds.DOWNLOAD],
    });

  const handleClose = () => navigate(-1);

  const handleDownloadExecution = (uri: string, reportName: string) => {
    downloadPdf(uri, reportName);
    handleClose();
    setIsProcessing(false);
  };

  const handleDownload = async () => {
    setIsProcessing(true);
    if (reportId) {
      const pdfLink = await fetchFileFromStorage(reportId, patientId);
      handleDownloadExecution(pdfLink, reportName);
    } else if (reportURI) {
      handleDownloadExecution(reportURI, reportName);
    }
  };

  if (loading || !locale || genericActionLoading || !genericAction) {
    return null;
  }

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.DOWNLOAD].download,
      onClick: handleDownload,
      disabled: isProcessing,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      type: 'underline',
      onClick: handleClose,
      disabled: isProcessing,
    },
  ];

  return (
    <Modal
      isOpen={true}
      title={locale.title}
      modalDesktopWidth="desktop:max-w-[600px]"
      disableMobileGrow={true}
      buttons={Buttons}
      onClose={handleClose}
    >
      <div className="flex flex-col desktop:flex-row gap-[30px] desktop:gap-[15px] bg-white rounded-lg px-5 py-3 text-base text-med-gray">
        <div className="flex justify-center desktop:justify-normal">
          <IconComponent
            width="w-12 desktop:w-6"
            height="h-12 desktop:h-6"
            icon={InfoIcon}
            fill="fill-clc-blue"
          />
        </div>
        <p>{locale.text}</p>
      </div>
    </Modal>
  );
};

export default ScanActionsDownloadWarning;
