import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MySkinRoutes } from 'app/my-skin/routes/MySkinRoutes';
import { MyAccountRoutes } from 'app/my-account/routes/MyAccountRoutes';
import { Camera } from 'app/my-skin/components/Camera';
import { BarcodeCamera } from 'app/manage-test-kits/components/BarcodeCamera';
import { Roles } from '../../firebase/interfaces';
import {
  DASHBOARD,
  MY_ACCOUNT_ROUTES,
  TEST_KITS_MANAGEMENT_BARCODE_CAMERA,
  TEST_KITS_MANAGEMENT_BARCODE_SCANNED,
  TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED,
  MY_SKIN_FACE_CAMERA,
  QR_ACTION,
  MY_SKIN_ROUTES,
  MY_SKIN_SCAN_COMPLETED,
  MY_SKIN_SUBMIT_PHOTO,
  SUPER_ADMIN_ROUTES,
  TEST_KITS_MANAGEMENT_ROUTES,
  MY_APPOINTMENT_ROUTES,
  MY_PATIENTS_ROUTES,
  PROVIDERS_ROUTES,
  FULLSCRIPT_ROUTES,
  DASHBOARD_FACE_CAMERA,
  DASHBOARD_SUBMIT_PHOTO,
  DASHBOARD_SCAN_COMPLETED,
  FACE_PHOTO,
  NOTIFICATIONS_ROUTES,
} from 'utilities/routes';

import PageLayout from 'layout/PageLayout';
import { AuthenticatedRoute } from 'router/AuthenticatedRoute';
import { QrProtectedRoute } from 'router/QrProtectedRoute';
import NetworkRequiredRoutes from 'components/networkRequiredRoutes';
import RoleRequiredRoute from 'components/RoleRequiredRoute';
import Loader from '../../components/loaderComponent';

const AppointmentFacePhotoRoutes = lazy(
  () => import('../my-appointments/pages/face-photo/routes'),
);
const DashboardPage = lazy(() => import('../dashboard/pages/Dashboard'));
const PhotoSubmission = lazy(
  () => import('app/my-skin/components/PhotoSubmission'),
);
const ScanCompleted = lazy(
  () => import('app/my-skin/components/ScanCompleted'),
);
const TestKitBarcodeScanned = lazy(
  () => import('app/manage-test-kits/components/TestKitBarcodeScanned'),
);
const BarcodeScanCompleted = lazy(
  () => import('app/manage-test-kits/components/BarcodeScanCompleted'),
);
const QrAction = lazy(() => import('app/qr-action/QrAction'));
const NotificationsRoutes = lazy(
  () => import('app/notifications/routes/NotificationsRoutes'),
);

const SuperAdminRoutes = lazy(
  () => import('app/superadmin/routes/SuperAdminRoutes'),
);
const ManageTestKitsRoutes = lazy(
  () => import('app/manage-test-kits/routes/ManageTestKitsRoutes'),
);
const MyAppointmentsRoutes = lazy(
  () => import('app/my-appointments/routes/MyAppointmentsRoutes'),
);
const MyPatientsRoutes = lazy(
  () => import('app/my-patients/routes/MyPatientsRoutes'),
);
const ProvidersViewSelectionRoutes = lazy(
  () =>
    import('app/providers-view-selection/routes/ProvidersViewSelectionRoutes'),
);
const FullscriptRoutes = lazy(() => import('app/fullscript/FullscriptRoutes'));

interface ProtectedRouteProps {
  children: React.ReactNode;
  roles: Roles[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ roles, children }) => {
  return (
    <AuthenticatedRoute>
      <PageLayout>
        <NetworkRequiredRoutes>
          <RoleRequiredRoute allowedRoles={roles}>{children}</RoleRequiredRoute>
        </NetworkRequiredRoutes>
      </PageLayout>
    </AuthenticatedRoute>
  );
};

export const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen">
          <Loader />
        </div>
      }
    >
      <Routes>
        <Route
          path={SUPER_ADMIN_ROUTES}
          element={
            <AuthenticatedRoute>
              <PageLayout>
                <NetworkRequiredRoutes>
                  <RoleRequiredRoute allowedRoles={[Roles.SUPERADMIN]}>
                    <SuperAdminRoutes />
                  </RoleRequiredRoute>
                </NetworkRequiredRoutes>
              </PageLayout>
            </AuthenticatedRoute>
          }
        />

        <Route path={QR_ACTION} element={<QrAction />} />

        <Route
          path={`${FACE_PHOTO}/*`}
          element={
            <QrProtectedRoute>
              <AppointmentFacePhotoRoutes />
            </QrProtectedRoute>
          }
        />

        <Route
          path={MY_SKIN_FACE_CAMERA}
          element={
            <QrProtectedRoute>
              <Camera />
            </QrProtectedRoute>
          }
        />

        <Route
          path={DASHBOARD_FACE_CAMERA}
          element={
            <QrProtectedRoute>
              <Camera />
            </QrProtectedRoute>
          }
        />

        <Route
          path={TEST_KITS_MANAGEMENT_BARCODE_CAMERA}
          element={
            <QrProtectedRoute>
              <BarcodeCamera />
            </QrProtectedRoute>
          }
        />

        <Route
          path={MY_SKIN_SUBMIT_PHOTO}
          element={
            <QrProtectedRoute>
              <PhotoSubmission />
            </QrProtectedRoute>
          }
        />

        <Route
          path={DASHBOARD_SUBMIT_PHOTO}
          element={
            <QrProtectedRoute>
              <PhotoSubmission />
            </QrProtectedRoute>
          }
        />

        <Route
          path={MY_SKIN_SCAN_COMPLETED}
          element={
            <QrProtectedRoute>
              <ScanCompleted />
            </QrProtectedRoute>
          }
        />

        <Route
          path={DASHBOARD_SCAN_COMPLETED}
          element={
            <QrProtectedRoute>
              <ScanCompleted />
            </QrProtectedRoute>
          }
        />

        <Route
          path={TEST_KITS_MANAGEMENT_BARCODE_SCANNED}
          element={
            <QrProtectedRoute>
              <TestKitBarcodeScanned />
            </QrProtectedRoute>
          }
        />

        <Route
          path={TEST_KITS_MANAGEMENT_BARCODE_SCAN_COMPLETED}
          element={
            <QrProtectedRoute>
              <BarcodeScanCompleted />
            </QrProtectedRoute>
          }
        />

        <Route
          path={DASHBOARD}
          element={
            <ProtectedRoute roles={[Roles.PATIENT, Roles.PROVIDER]}>
              <DashboardPage />
            </ProtectedRoute>
          }
        />

        <Route
          path={MY_PATIENTS_ROUTES}
          element={
            <ProtectedRoute roles={[Roles.PROVIDER]}>
              <MyPatientsRoutes />
            </ProtectedRoute>
          }
        />

        <Route
          path={FULLSCRIPT_ROUTES}
          element={
            <ProtectedRoute roles={[Roles.PROVIDER]}>
              <FullscriptRoutes />
            </ProtectedRoute>
          }
        />

        <Route
          path={MY_SKIN_ROUTES}
          element={
            <ProtectedRoute roles={[Roles.PATIENT]}>
              <MySkinRoutes />
            </ProtectedRoute>
          }
        />

        <Route
          path={TEST_KITS_MANAGEMENT_ROUTES}
          element={
            <ProtectedRoute roles={[Roles.PATIENT]}>
              <ManageTestKitsRoutes />
            </ProtectedRoute>
          }
        />

        <Route
          path={MY_ACCOUNT_ROUTES}
          element={
            <ProtectedRoute roles={[Roles.PATIENT, Roles.PROVIDER]}>
              <MyAccountRoutes />
            </ProtectedRoute>
          }
        />

        <Route
          path={NOTIFICATIONS_ROUTES}
          element={
            <ProtectedRoute roles={[Roles.PATIENT, Roles.PROVIDER]}>
              <NotificationsRoutes />
            </ProtectedRoute>
          }
        />

        <Route
          path={MY_APPOINTMENT_ROUTES}
          element={
            <ProtectedRoute roles={[Roles.PATIENT, Roles.PROVIDER]}>
              <MyAppointmentsRoutes />
            </ProtectedRoute>
          }
        />

        <Route
          path={PROVIDERS_ROUTES}
          element={
            <ProtectedRoute roles={[Roles.PATIENT]}>
              <ProvidersViewSelectionRoutes />
            </ProtectedRoute>
          }
        />

        <Route path="/*" element={<Navigate to={DASHBOARD} />} />
      </Routes>
    </Suspense>
  );
};
