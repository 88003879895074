import { twMerge } from 'tailwind-merge';

interface CarouselDotsProps {
  selectedDotIndex: number;
  carouselLength: number;
  className?: string;
  dotClassName?: string;
}

const CarouselDots: React.FC<CarouselDotsProps> = ({
  selectedDotIndex,
  carouselLength,
  className,
  dotClassName,
}) => {
  const getDotClass = (index: number): string => {
    return index === selectedDotIndex ? 'bg-clc-blue' : 'bg-black-blur';
  };

  const renderCarouselItems = () => {
    return Array.from({ length: carouselLength }, (_, index) => (
      <div
        key={index}
        className="flex flex-row items-center justify-center p-0 gap-1.5"
      >
        <div
          className={twMerge(
            'w-2 h-2 rounded-full',
            dotClassName,
            getDotClass(index),
          )}
        />
      </div>
    ));
  };

  return (
    <div className={twMerge('flex flex-row items-start p-0 gap-5', className)}>
      {renderCarouselItems()}
    </div>
  );
};

export default CarouselDots;
