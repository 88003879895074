import { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { AuthContext } from 'auth/context/AuthContext';
import {
  Persona_Inquiry_Status_Enum,
  useUpdateProfileNotificationMutation,
} from 'graphql/generated/hasura';
import { componentIds, genericActionsIds, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';

import { useModalParams } from 'components/modal/useModalManager';
import { AccountDeletion } from './components/patientComponents/AccountDeletion';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AUTH_MFA_NUMBER_CHANGED,
  AUTH_MFA_REQUIRES_RECENT_LOGIN,
  MY_ACCOUNT_HEALTH_QUESTIONNAIRE,
  MY_PROFILE_VALIDATE_PERSONA,
  TEST_KITS_MANAGEMENT_TEST_KITS,
} from 'utilities/routes';
import {
  AddressInformation,
  ContactData,
  GeneralStates,
  PersonalData,
  PharmacyData,
  ProfileData,
  ProviderProfileDataOptional,
  SecurityAndPrivacyData,
  UserMeasurements,
} from 'app/my-account/interfaces/profile.interfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { normalizePhoneNumber, scrollToTop } from 'utilities/functions';
import { ProfileSectionTypes, ProviderProfileSectionTypes } from './enums';
import { firebaseAuth } from '../../../../firebase/config';
import { PasswordChange } from './components/patientComponents/PasswordChange';
import { min7OnlyDigitsPattern, onlyDigitsPattern } from 'utilities/variables';
import {
  useFirebaseSignOut,
  useFirebaseSmsMfa,
} from '../../../../firebase/hooks';
import { FIREBASE_ERROR_CODES, Roles } from '../../../../firebase/interfaces';
import { FirebaseError } from 'firebase/app';
import {
  addressInfoRequiredFields,
  contactInfoRequiredField,
  personalInfoRequiredFields,
  userMeasurementsRequiredFields,
} from './components/patientComponents/constants';
import Loader from 'components/loaderComponent';
import parsePhoneNumberFromString, {
  parsePhoneNumber,
} from 'libphonenumber-js';
import useUserValidation from 'hooks/useUserValidation';
import { PhoneMultiFactorInfo, multiFactor } from 'firebase/auth';
import { SelectWithFlagsValues } from 'components/inputComponent';
import { useGetComponent } from 'hooks/useGetComponent';
import { usePatchFhirPatientPersonalDataMutation } from 'graphql/generated/remote-schema-hasura';
import useFhirProviderDataLoader from 'hooks/useFhirProviderDataLoader';
import useProfileDataLoader from 'hooks/usePatientDataLoader';
import { PatientModule } from './components/modules/PatientModule';
import { ProviderModule } from './components/modules/ProviderModule';
import { ValidateInformationModal } from './components/personaComponents/ValidateInformationModal';
import useUserVerificationStatus, {
  UserVerificationStatus,
} from 'hooks/useUserVerificationStatus';
import { ValidatePersonaModal } from './components/personaComponents/ValidatePersonaModal';
import { AlertProps } from './components/modules/interfaces/patientModuleInterfaces';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';
import { isEqual } from 'lodash';

const validatePhoneNumber = (phoneNumber: string) =>
  new RegExp(min7OnlyDigitsPattern).test(phoneNumber);

export const MyProfilePage: React.FC = () => {
  const location = useLocation();
  const referringPage = location.state?.referringPage || '';
  const isDnaTestKitsFlow = location.state?.isDnaTestKitsFlow || false;
  const healthQuestionnareCompleted =
    location.state?.healthQuestionnareCompleted || false;
  const successToSaveQuestionnaire =
    location.state?.successToSaveQuestionnaire || false;

  const { enablePersonaVerification } = useFlags<CodexFeatureFlags>();
  const getMfaParts = (mfaPhoneNumber?: string) => {
    try {
      const parsedPhoneNumber = parsePhoneNumber(mfaPhoneNumber || '');
      return {
        mfaPhone: parsedPhoneNumber.nationalNumber,
        mfaCountry: parsedPhoneNumber.countryCallingCode,
      };
    } catch (error) {
      console.error('Unable to parse phone number');
      return {
        mfaPhone: '',
        mfaCountry: '',
      };
    }
  };

  const { isOpen: isAccountDeletionModalOpen } =
    useModalParams('account-deletion');

  const { isOpen: isPasswordChangeModalOpen } =
    useModalParams('password-change');

  const { isOpen: isValidateInformationOpen } = useModalParams(
    'validate-information',
  );

  const { isOpen: isLockedValidationOpen } = useModalParams(
    'locked-verification',
  );

  const { isOpen: isValidatePersonaOpen } = useModalParams('validate-persona');

  const navigate = useNavigate();
  const signOutUser = useFirebaseSignOut();

  const { user: loggedUser } = useContext(AuthContext);

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PROFILE,
  });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.UPDATE, genericActionsIds.VERIFY],
    });

  const { userValidation, loading: loadingUserValidation } =
    useUserValidation();

  const { userVerificationValidation, loading: loadingUserVerificationStatus } =
    useUserVerificationStatus();

  const {
    data: dynamicQuestionnaireLocale,
    loading: dynamicQuestionnaireLocaleLoading,
  } = useGetComponent({
    locale: 'en',
    componentId: componentIds.DYNAMIC_QUESTIONNAIRE,
  });

  const [updateProfileNotification] = useUpdateProfileNotificationMutation({});

  const {
    fhirProviderDataLoading,
    providerProfileData,
    setProviderProfileData,
    initialProviderProfileData,
    updater,
  } = useFhirProviderDataLoader(loggedUser, loggedUser?.email);

  const isBlockLocation = useMemo(
    () => !isEqual(initialProviderProfileData, providerProfileData),
    [providerProfileData, initialProviderProfileData],
  );

  const { fhirPatientDataLoading, profileData, setProfileData } =
    useProfileDataLoader(loggedUser);

  const { data: firebaseLocale, loading: firebaseLocaleLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });

  const [patchFhirPatientPersonalData] =
    usePatchFhirPatientPersonalDataMutation({});

  const { handleVerifyPhoneNumber, multifactorEnroll } =
    useFirebaseSmsMfa(firebaseLocale);

  const [alertProps, setAlertProps] = useState<AlertProps | null>();
  const [userCompletedData, setUserCompletedData] = useState({
    profile: false,
    contact: false,
    healthQuestionnaire: false,
  });

  const [userVerificationStatus, setUserVerificationStatus] =
    useState<UserVerificationStatus>({
      status: null,
    });

  const [currentSidebarItem, setCurrentSidebarItem] = useState<string>('');
  const [displayMFAForm, setDisplayMFAForm] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string>('');
  const [verificationId, setVerificationId] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [mfaError, setMfaError] = useState<string>('');
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [displaySecurityAndPrivacy, setDisplaySecurityAndPrivacy] =
    useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [securityAndPrivacySubmitting, setSecurityAndPrivacySubmitting] =
    useState<boolean>(false);

  useEffect(() => {
    if (loggedUser?.role === Roles.PATIENT) {
      const section: ProfileSectionTypes = location.state?.section;
      if (locale) {
        if (section) {
          switch (section) {
            case ProfileSectionTypes.PROFILE:
              setCurrentSidebarItem(locale?.personalInfo?.title);
              break;
            case ProfileSectionTypes.CONTACT:
              setCurrentSidebarItem(locale?.contactInfo?.title);
              break;
            case ProfileSectionTypes.SECURITY_AND_PRIVACY:
              setCurrentSidebarItem(locale?.securityAndPrivacy?.title);
              break;
            default:
              setCurrentSidebarItem(locale?.personalInfo?.title);
              console.error('An error ocurred while navigating.');
              break;
          }
        } else {
          setCurrentSidebarItem(locale?.personalInfo?.title);
        }
      }
    }
  }, [location, locale, loggedUser?.role]);

  useEffect(() => {
    if (loggedUser?.role === Roles.PROVIDER) {
      const section: ProviderProfileSectionTypes = location.state?.section;
      if (locale) {
        if (section) {
          switch (section) {
            case ProviderProfileSectionTypes.ACCOUNT_INFO:
              setCurrentSidebarItem(
                locale?.providerLocales?.accountInfoSection,
              );
              break;
            case ProviderProfileSectionTypes.PERSONAL_INFO:
              setCurrentSidebarItem(
                locale?.providerLocales?.personalInfoSection,
              );
              break;
            default:
              setCurrentSidebarItem(
                locale?.providerLocales?.accountInfoSection,
              );
              console.error('An error occurred while navigating.');
              break;
          }
        } else {
          setCurrentSidebarItem(locale?.providerLocales?.accountInfoSection);
        }
      }
    }
  }, [location, locale, loggedUser?.role]);

  const handleUpdatedPersonalInfo = (state: GeneralStates) => {
    if (state === GeneralStates.SUCCESS) {
      if (!userCompletedData.contact && isDnaTestKitsFlow) {
        setCurrentSidebarItem(locale?.contactInfo?.title);
        return setAlertProps({
          type: 'positive',
          text: locale?.personalInfo.personalInfoUpdate,
        });
      }
      if (!userCompletedData.healthQuestionnaire && isDnaTestKitsFlow)
        return navigate(MY_ACCOUNT_HEALTH_QUESTIONNAIRE, {
          state: { isDnaTestKitsFlow: true },
        });
      if (isDnaTestKitsFlow) {
        return navigate(referringPage, {
          state: { dnaFlowCompleted: true },
        });
      }
      setAlertProps({
        type: 'positive',
        text: locale?.personalInfo.personalInfoUpdate,
      });
    } else if (state === GeneralStates.MISSING_INFO) {
      setAlertProps({ type: 'warning', text: locale?.requiredFieldsMissing });
      return scrollToTop();
    } else {
      setAlertProps({
        type: 'warning',
        text: locale?.personalInfo.personalInfoError,
      });
    }
  };

  const handleUpdateSecurityAndPrivacy = (state: GeneralStates) => {
    if (state === GeneralStates.SUCCESS) {
      setAlertProps({
        type: 'positive',
        text: locale?.securityAndPrivacy.securityAndPrivacyUpdate,
      });
    } else {
      setAlertProps({
        type: 'warning',
        text: locale?.securityAndPrivacy.securityAndPrivacyError,
      });
    }
  };

  const handleUpdatedContactInfo = (state: GeneralStates) => {
    if (state === GeneralStates.SUCCESS) {
      if (!userCompletedData.profile && isDnaTestKitsFlow) {
        setCurrentSidebarItem(locale?.personalInfo?.title);
        return setAlertProps({
          type: 'positive',
          text: locale.contactInfo?.contactInfoUpdate,
        });
      }
      if (!userCompletedData.healthQuestionnaire && isDnaTestKitsFlow) {
        return navigate(MY_ACCOUNT_HEALTH_QUESTIONNAIRE, {
          state: { isDnaTestKitsFlow: true },
        });
      }
      if (isDnaTestKitsFlow) {
        return navigate(TEST_KITS_MANAGEMENT_TEST_KITS, {
          state: { dnaFlowCompleted: true },
        });
      }
      setAlertProps({
        type: 'positive',
        text: locale?.contactInfo.contactInfoUpdate,
      });
    } else if (state === GeneralStates.MISSING_INFO) {
      setAlertProps({ type: 'warning', text: locale?.requiredFieldsMissing });
      return scrollToTop();
    } else {
      setAlertProps({
        type: 'warning',
        text: locale?.contactInfo.contactInfoError,
      });
    }
  };

  const handleUpdatedPharmacyInfo = (state: GeneralStates) => {
    if (state === GeneralStates.SUCCESS) {
      if (!userCompletedData.profile) {
        return setAlertProps({
          type: 'positive',
          text: locale.pharmacies?.pharmacyInfoUpdate,
        });
      }
      setAlertProps({
        type: 'positive',
        text: locale?.pharmacies.pharmacyInfoUpdate,
      });
    } else if (state === GeneralStates.MISSING_INFO) {
      setAlertProps({ type: 'warning', text: locale?.requiredFieldsMissing });
      return scrollToTop();
    } else {
      setAlertProps({
        type: 'warning',
        text: locale?.pharmacies.pharmacyInfoError,
      });
    }
  };

  const handleUpdatedProviderAccountInfo = (state: GeneralStates) => {
    if (state === GeneralStates.SUCCESS) {
      updater();
      if (!userCompletedData.contact && isDnaTestKitsFlow) {
        setCurrentSidebarItem(locale?.contactInfo?.title);
        return setAlertProps({
          type: 'positive',
          text: locale?.personalInfo.personalInfoUpdate,
        });
      }
      if (!userCompletedData.healthQuestionnaire && isDnaTestKitsFlow)
        return navigate(MY_ACCOUNT_HEALTH_QUESTIONNAIRE, {
          state: { isDnaTestKitsFlow: true },
        });
      if (isDnaTestKitsFlow) {
        return navigate(referringPage, {
          state: { dnaFlowCompleted: true },
        });
      }
      setAlertProps({
        type: 'positive',
        text: locale?.personalInfo.personalInfoUpdate,
      });
    } else if (state === GeneralStates.MISSING_INFO) {
      setAlertProps({ type: 'warning', text: locale?.requiredFieldsMissing });
      return scrollToTop();
    } else {
      setAlertProps({
        type: 'warning',
        text: locale?.personalInfo.personalInfoError,
      });
    }
  };

  const updateProfileNotifications = async () => {
    await updateProfileNotification();
  };

  const validateRequiredFields = (): boolean => {
    for (const key in profileData) {
      if (key === 'etnicity') {
        if (profileData.etnicity.length === 0) return false;
      }
      if (key === 'addressInformation') {
        for (const requiredField of addressInfoRequiredFields) {
          if (!profileData[key][requiredField as keyof AddressInformation])
            return false;
        }
      }
      if (key === 'userMeasurements') {
        for (const requiredField of userMeasurementsRequiredFields) {
          if (!profileData[key][requiredField as keyof UserMeasurements])
            return false;
        }
      }
      if (
        personalInfoRequiredFields.find(
          (requiredField) => requiredField === key,
        ) ||
        contactInfoRequiredField.find((requiredField) => requiredField === key)
      ) {
        if (!profileData[key as keyof ProfileData]) {
          return false;
        }
      }
    }
    return true;
  };

  const handleOnSubmit = async () => {
    const isFormInvalid = validateRequiredFields();
    if (!isFormInvalid) {
      setAlertProps({ type: 'warning', text: locale?.requiredFieldsMissing });
      return scrollToTop();
    }

    try {
      setSubmitting(true);

      const formattedAddressInformation = {
        ...profileData?.addressInformation,
        state: profileData?.addressInformation?.state?.split(' - ')[0],
      };

      const response = await patchFhirPatientPersonalData({
        variables: {
          patientPersonalInfo: {
            codexID: loggedUser?.uuid || '',
            SENSITIVE_dob: profileData?.dob || null,
            SENSITIVE_etnicity: profileData?.etnicity || null,
            SENSITIVE_firstname: profileData?.firstname || null,
            SENSITIVE_country: profileData?.country || null,
            SENSITIVE_gender: profileData?.gender || null,
            SENSITIVE_lastname: profileData?.lastname || null,
            SENSITIVE_phone: profileData?.phone || null,
            SENSITIVE_self_identity_gender:
              profileData?.selfIdentifyGender || null,
            SENSITIVE_address_information: {
              address1: formattedAddressInformation.addressLine1 || null,
              address2: formattedAddressInformation.addressLine2 || null,
              city: formattedAddressInformation.city || null,
              country: (formattedAddressInformation.country as string) || null,
              state:
                profileData?.addressInformation?.state?.split(' - ')[0] || null,
              zipCode: profileData?.addressInformation?.zipCode || null,
            },
            SENSITIVE_user_measurements: {
              heightFt: Number(profileData?.userMeasurements?.heightFt) || null,
              heightIn: Number(profileData?.userMeasurements?.heightIn) || null,
              weight: Number(profileData?.userMeasurements?.weight) || null,
              hips: Number(profileData?.userMeasurements?.hips) || null,
              waist: Number(profileData?.userMeasurements?.waist) || null,
            },
            SENSITIVE_pharmacies: profileData?.pharmacyList || null,
          },
        },
      });

      if (!response.data) {
        setAlertProps({
          type: 'warning',
          text: locale?.personalAndContactInfoError,
        });
        scrollToTop();
        throw new Error('Failed to update user');
      }

      if (profileData?.mfaPhone && profileData?.mfaCountry) {
        const callingCode = profileData.mfaCountry.replace(
          onlyDigitsPattern,
          '',
        );
        const formattedPhoneNumber = parsePhoneNumberFromString(
          profileData.mfaPhone,
          { defaultCallingCode: callingCode },
        );

        if (
          formattedPhoneNumber &&
          loggedUser?.mfaPhoneNumber !== formattedPhoneNumber.number
        ) {
          setDisplaySecurityAndPrivacy(true);
          handleOnSavePhoneNumber(true);
          return;
        }
      }

      await updateProfileNotifications();

      if (!userCompletedData.healthQuestionnaire) {
        return navigate(MY_ACCOUNT_HEALTH_QUESTIONNAIRE, {
          state: { isDnaTestKitsFlow: true },
        });
      }

      setSubmitting(false);
      setAlertProps({
        type: 'positive',
        text: locale?.myProfileUpdate,
      });
      scrollToTop();
    } catch (error: unknown) {
      console.log(error);
      setSubmitting(false);
      setAlertProps({
        type: 'warning',
        text: locale?.myProfileError,
      });
      scrollToTop();
      throw new Error('Failed to update user');
    }
  };

  const handleProfileInfoChange = useCallback(
    (
      data: PersonalData | ContactData | SecurityAndPrivacyData | PharmacyData,
    ): void => {
      setProfileData((prevProfileData) => {
        return { ...prevProfileData, ...data } as ProfileData;
      });
    },
    [setProfileData],
  );

  const handleProviderProfileInfoChange = useCallback(
    (data: ProviderProfileDataOptional): void => {
      setProviderProfileData((prevProfileData) => {
        return { ...prevProfileData, ...data } as ProviderProfileDataOptional;
      });
    },
    [setProviderProfileData],
  );

  const handleOnDisplayMFAForm = (displayForm: boolean) =>
    setDisplayMFAForm(displayForm);

  const handleResendCode = async () => {
    try {
      await handleSendCode();
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_REQUIRES_RECENT_LOGIN:
            await signOutUser();
            navigate(AUTH_MFA_REQUIRES_RECENT_LOGIN);
            break;
          case FIREBASE_ERROR_CODES.AUTH_TOO_MANY_REQUESTS:
            setDisplayMFAForm(false);
            setPhoneError(locale.securityAndPrivacy.tooManyRequests);
            break;
          default:
            console.error(error);
            setPhoneError(locale.securityAndPrivacy.unexpectedError);
            break;
        }
      } else {
        console.error(error);
        setPhoneError(locale.securityAndPrivacy.unexpectedError);
      }
    }
  };

  const handleSendCode = async (mobileVersion?: boolean) => {
    const currentUser = firebaseAuth.currentUser;
    const callingCode = profileData?.mfaCountry.replace(onlyDigitsPattern, '');
    const formattedPhoneNumber = parsePhoneNumberFromString(
      profileData?.mfaPhone || '',
      { defaultCallingCode: callingCode },
    );

    if (currentUser && formattedPhoneNumber) {
      const verificationId = await handleVerifyPhoneNumber(
        currentUser,
        formattedPhoneNumber.number,
        'recaptcha-container',
      );
      setVerificationId(verificationId);
      setDisplayMFAForm(true);
      mobileVersion
        ? setSubmitting(false)
        : setSecurityAndPrivacySubmitting(false);
    }
  };

  const handleOnSavePhoneNumber = async (mobileVersion?: boolean) => {
    !mobileVersion && setSecurityAndPrivacySubmitting(true);

    if (!validatePhoneNumber(profileData?.mfaPhone || '')) {
      setPhoneError(locale.securityAndPrivacy.phoneError);
      return;
    }

    try {
      await handleSendCode(mobileVersion);
    } catch (error) {
      mobileVersion
        ? setSubmitting(false)
        : setSecurityAndPrivacySubmitting(false);
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case FIREBASE_ERROR_CODES.AUTH_REQUIRES_RECENT_LOGIN:
            await signOutUser();
            navigate(AUTH_MFA_REQUIRES_RECENT_LOGIN);
            break;
          case FIREBASE_ERROR_CODES.AUTH_INVALID_PHONE_NUMBER:
            setPhoneError(locale.securityAndPrivacy.phoneError);
            break;
          case FIREBASE_ERROR_CODES.AUTH_SECOND_FACTOR_ALREADY_IN_USE:
            setPhoneError(locale.securityAndPrivacy.phoneNumberAlreadyInUse);
            break;
          case FIREBASE_ERROR_CODES.AUTH_TOO_MANY_REQUESTS:
            setPhoneError(locale.securityAndPrivacy.tooManyRequests);
            break;
          default:
            console.error(error);
            setPhoneError(locale.securityAndPrivacy.unexpectedError);
            break;
        }
      } else {
        console.error(error);
        setPhoneError(locale.securityAndPrivacy.unexpectedError);
      }
    }
  };

  const handleVerifyMFACode = async (mobileVersion?: boolean) => {
    mobileVersion ? setSubmitting(true) : setSecurityAndPrivacySubmitting(true);

    const currentUser = firebaseAuth.currentUser;

    if (currentUser) {
      try {
        await multifactorEnroll(currentUser, verificationId, code);

        const callingCode = profileData?.mfaCountry.replace(
          onlyDigitsPattern,
          '',
        );
        const formattedPhoneNumber = parsePhoneNumberFromString(
          profileData?.mfaPhone || '',
          {
            defaultCallingCode: callingCode,
          },
        );

        if (formattedPhoneNumber) {
          const multifactor = multiFactor(currentUser);
          const enrolledFactors = multifactor.enrolledFactors;
          const factorsToDelete = enrolledFactors.filter((factor) => {
            const phoneMultiFactorInfo = factor as PhoneMultiFactorInfo;
            return (
              phoneMultiFactorInfo.phoneNumber !== formattedPhoneNumber.number
            );
          });

          for (const factor of factorsToDelete) {
            await multifactor.unenroll(factor.uid);
          }

          setDisplayMFAForm(false);
          if (mobileVersion) {
            await updateProfileNotifications();
          }
          mobileVersion
            ? setSubmitting(false)
            : setSecurityAndPrivacySubmitting(false);
          await signOutUser();
          navigate(AUTH_MFA_NUMBER_CHANGED);
        }
      } catch (error) {
        mobileVersion
          ? setSubmitting(false)
          : setSecurityAndPrivacySubmitting(false);
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case FIREBASE_ERROR_CODES.AUTH_INVALID_VERIFICATION_CODE:
            case FIREBASE_ERROR_CODES.AUTH_MISSING_CODE:
              setMfaError(locale.securityAndPrivacy.invalidVerificationCode);
              break;
            default:
              console.error(error);
              setDisplayMFAForm(false);
              handleUpdateSecurityAndPrivacy(GeneralStates.ERROR);
              scrollToTop();
              break;
          }
        } else {
          console.error(error);
          setDisplayMFAForm(false);
          handleUpdateSecurityAndPrivacy(GeneralStates.ERROR);
          scrollToTop();
        }
      }
    }
  };

  const handleOnPhoneNumberChange = (phone: string) => {
    const normalizedPhoneNumber = normalizePhoneNumber(phone);
    const validePhoneNumber = validatePhoneNumber(normalizedPhoneNumber);
    setPhoneError(
      !validePhoneNumber ? locale.securityAndPrivacy.phoneError : '',
    );
    setDisableButton(!validePhoneNumber);

    setProfileData(
      (prevProfileData) =>
        ({
          ...prevProfileData,
          mfaPhone: normalizedPhoneNumber,
        } as ProfileData),
    );
  };

  const handleOnCountryChange = (country: SelectWithFlagsValues) =>
    setProfileData(
      (prevProfileData) =>
        ({
          ...prevProfileData,
          mfaCountry: country.value,
        } as ProfileData),
    );

  const userVerificationAlertFactory = useCallback(
    (status: Persona_Inquiry_Status_Enum | null | undefined) => {
      switch (status) {
        case Persona_Inquiry_Status_Enum.Failed:
        case null:
        case undefined:
          return setAlertProps({
            type: 'info',
            text: locale?.personaLocales?.personaAlerts?.alertBodyText1,
            isActionCta: true,
            actionLabel: locale?.personaLocales?.personaAlerts?.alertCta1,
            isNotification: true,
            actionCallback: () => navigate(MY_PROFILE_VALIDATE_PERSONA),
          });
        case Persona_Inquiry_Status_Enum.Started:
        case Persona_Inquiry_Status_Enum.Created:
          return setAlertProps({
            type: 'info',
            text: locale?.personaLocales?.personaAlerts?.alertBodyText6,
            isActionCta: true,
            actionLabel: locale?.personaLocales?.personaAlerts?.alertCta6,
            isNotification: true,
            actionCallback: () => navigate(MY_PROFILE_VALIDATE_PERSONA),
          });
        case Persona_Inquiry_Status_Enum.MarkedForReview:
        case Persona_Inquiry_Status_Enum.Completed:
          return setAlertProps({
            type: 'positive',
            text: locale?.personaLocales?.personaAlerts?.alertBodyText2,
            isNotification: true,
          });
        case Persona_Inquiry_Status_Enum.Approved:
          return setAlertProps({
            type: 'positive',
            text: locale?.personaLocales?.personaAlerts?.alertBodyText3,
            isNotification: true,
          });
        case Persona_Inquiry_Status_Enum.Declined:
          return setAlertProps({
            type: 'warning',
            text: locale?.personaLocales?.personaAlerts?.alertBodyText4,
            isActionCta: true,
            actionLabel: locale?.personaLocales?.personaAlerts?.alertCta1,
            isNotification: true,
            actionCallback: () => navigate(MY_PROFILE_VALIDATE_PERSONA),
          });
        case Persona_Inquiry_Status_Enum.Expired:
          return setAlertProps({
            type: 'info',
            text: locale?.personaLocales?.personaAlerts?.alertBodyText1,
            isActionCta: true,
            actionLabel: locale?.personaLocales?.personaAlerts?.alertCta1,
            isNotification: true,
            actionCallback: () => navigate(MY_PROFILE_VALIDATE_PERSONA),
          });
        case Persona_Inquiry_Status_Enum.Locked:
          return setAlertProps({
            type: 'warning',
            text: (
              <>
                <p className="text-base font-semibold text-alert-negative text-left">
                  {locale?.personaLocales?.personaAlerts?.alertBodyText5}
                  <a
                    className="ml-1 underline text-clc-blue"
                    // TODO COD-2379
                    href="mailto:placeholder@gmail.com"
                  >
                    {
                      locale?.personaLocales?.personaAlerts
                        ?.alertBodyText5Helper
                    }
                  </a>
                </p>
              </>
            ),
            actionCallback: () => navigate(MY_PROFILE_VALIDATE_PERSONA),
          });
        default:
          setAlertProps(null);
          break;
      }
    },
    [
      locale?.personaLocales?.personaAlerts?.alertBodyText1,
      locale?.personaLocales?.personaAlerts?.alertBodyText2,
      locale?.personaLocales?.personaAlerts?.alertBodyText3,
      locale?.personaLocales?.personaAlerts?.alertBodyText4,
      locale?.personaLocales?.personaAlerts?.alertBodyText5,
      locale?.personaLocales?.personaAlerts?.alertBodyText5Helper,
      locale?.personaLocales?.personaAlerts?.alertBodyText6,
      locale?.personaLocales?.personaAlerts?.alertCta1,
      locale?.personaLocales?.personaAlerts?.alertCta6,
      navigate,
    ],
  );

  useEffect(() => {
    if (loggedUser?.role === Roles.PATIENT) {
      const validateUse = async () => {
        const {
          isContactInfoValid,
          isPersonalInfoValid,
          isQuestionnaireValid,
        } = await userValidation();
        setUserCompletedData({
          contact: isContactInfoValid,
          healthQuestionnaire: isQuestionnaireValid,
          profile: isPersonalInfoValid,
        });
      };
      validateUse();
    }
  }, [currentSidebarItem, loggedUser?.role, userValidation]);

  useEffect(() => {
    if (enablePersonaVerification && loggedUser?.role === Roles.PATIENT) {
      const validateUserStatus = async () => {
        const { userVerificationStatus } = await userVerificationValidation();
        setUserVerificationStatus({
          status: userVerificationStatus,
        });

        setUserVerificationStatus({
          status: userVerificationStatus,
        });

        userVerificationAlertFactory(userVerificationStatus);
      };
      validateUserStatus().catch((e) =>
        console.log(
          'An error happened while validating the user verification status',
          e,
        ),
      );
    }
  }, [
    currentSidebarItem,
    enablePersonaVerification,
    loggedUser?.role,
    userVerificationAlertFactory,
    userVerificationValidation,
  ]);

  useEffect(() => {
    if (enablePersonaVerification && loggedUser?.role === Roles.PATIENT) {
      userVerificationAlertFactory(userVerificationStatus.status);
    }
  }, [
    enablePersonaVerification,
    loggedUser?.role,
    userVerificationAlertFactory,
    userVerificationStatus.status,
  ]);

  useEffect(() => {
    if (healthQuestionnareCompleted) {
      setAlertProps({
        type: 'positive',
        text: locale?.healthQuestionnaireCompleted,
      });
    }
  }, [healthQuestionnareCompleted, locale]);

  useEffect(() => {
    if (successToSaveQuestionnaire) {
      setAlertProps({
        type: 'positive',
        text: dynamicQuestionnaireLocale.questionnaireUpdatedSuccessfully,
      });
    }
  }, [successToSaveQuestionnaire, dynamicQuestionnaireLocale]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(location.pathname, {
        replace: true,
        state: {
          ...location.state,
          healthQuestionnareCompleted: false,
          successToSaveQuestionnaire: false,
          section: currentSidebarItem,
        },
      });
      setAlertProps(null);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location, navigate, currentSidebarItem]);

  const { isOpen: isNpiInfoOpen } = useModalParams('npi-info');

  useEffect(() => {
    const updateProfileData = async () => {
      if (profileData?.phone === 'null' || profileData?.country === 'null') {
        const mfaParts = getMfaParts(loggedUser?.mfaPhoneNumber);

        setProfileData({
          ...profileData,
          phone: mfaParts.mfaPhone,
          country: mfaParts.mfaCountry,
        });

        try {
          const response = await patchFhirPatientPersonalData({
            variables: {
              patientPersonalInfo: {
                codexID: loggedUser?.uuid || '',
                SENSITIVE_country: mfaParts.mfaCountry || null,
                SENSITIVE_phone: mfaParts.mfaPhone || null,
              },
            },
          });

          if (!response.data) {
            console.log('Error while patching phone data on FHIR');
          }
        } catch (error) {
          console.log('Error while patching phone data on FHIR', error);
        }
      }
    };

    updateProfileData();
  }, [
    loggedUser?.mfaPhoneNumber,
    loggedUser?.uuid,
    patchFhirPatientPersonalData,
    profileData,
    setProfileData,
  ]);

  if (loggedUser?.role === Roles.PATIENT) {
    if (
      loading ||
      !locale ||
      genericActionLoading ||
      !genericAction ||
      firebaseLocaleLoading ||
      !firebaseLocale ||
      profileData === null ||
      loadingUserValidation ||
      (dynamicQuestionnaireLocaleLoading && !dynamicQuestionnaireLocale) ||
      fhirPatientDataLoading ||
      loadingUserVerificationStatus
    ) {
      return <Loader />;
    }
  }

  if (loggedUser?.role === Roles.PROVIDER) {
    if (
      loading ||
      !locale ||
      genericActionLoading ||
      !genericAction ||
      firebaseLocaleLoading ||
      !firebaseLocale ||
      providerProfileData === null ||
      fhirProviderDataLoading
    ) {
      return <Loader />;
    }
  }

  if (isAccountDeletionModalOpen) {
    return <AccountDeletion />;
  }

  if (isPasswordChangeModalOpen) {
    return <PasswordChange />;
  }

  if (isValidateInformationOpen && enablePersonaVerification) {
    return (
      <ValidateInformationModal
        locale={locale.personaLocales.personaWidget.hoverText}
      />
    );
  }

  if (isValidatePersonaOpen && enablePersonaVerification) {
    return (
      <ValidatePersonaModal
        locale={locale.personaLocales.personaModal}
        userVerificationStatus={userVerificationStatus}
        profileData={profileData || providerProfileData}
      />
    );
  }

  if (isLockedValidationOpen && enablePersonaVerification) {
    return (
      <ValidateInformationModal
        locale={locale.personaLocales.personaAlerts.alertBadge4PopupText}
      />
    );
  }

  return (
    <>
      {loggedUser?.role === Roles.PATIENT && (
        <PatientModule
          alertProps={alertProps}
          disableButton={disableButton}
          displayMFAForm={displayMFAForm}
          displaySecurityAndPrivacy={displaySecurityAndPrivacy}
          handleOnCountryChange={handleOnCountryChange}
          handleOnDisplayMFAForm={handleOnDisplayMFAForm}
          handleOnPhoneNumberChange={handleOnPhoneNumberChange}
          handleOnSavePhoneNumber={handleOnSavePhoneNumber}
          handleOnSubmit={handleOnSubmit}
          handleProfileInfoChange={handleProfileInfoChange}
          handleResendCode={handleResendCode}
          handleUpdatedContactInfo={handleUpdatedContactInfo}
          handleUpdatedPersonalInfo={handleUpdatedPersonalInfo}
          handleUpdatedPharmacyInfo={handleUpdatedPharmacyInfo}
          handleVerifyMFACode={handleVerifyMFACode}
          mfaError={mfaError}
          phoneError={phoneError}
          profileData={profileData}
          securityAndPrivacySubmitting={securityAndPrivacySubmitting}
          setAlertProps={setAlertProps}
          setCode={setCode}
          setDisplaySecurityAndPrivacy={setDisplaySecurityAndPrivacy}
          submitting={submitting}
          currentSidebarItem={currentSidebarItem}
          setCurrentSidebarItem={setCurrentSidebarItem}
          userVerificationStatus={userVerificationStatus.status}
        />
      )}

      {loggedUser?.role === Roles.PROVIDER && (
        <ProviderModule
          alertProps={alertProps}
          currentSidebarItem={currentSidebarItem}
          displayMFAForm={displayMFAForm}
          handleOnSubmit={handleOnSubmit}
          handleProviderProfileInfoChange={handleProviderProfileInfoChange}
          handleUpdatedProviderAccountInfo={handleUpdatedProviderAccountInfo}
          handleVerifyMFACode={handleVerifyMFACode}
          isNpiInfoOpen={isNpiInfoOpen}
          providerProfileData={providerProfileData}
          setAlertProps={setAlertProps}
          setCurrentSidebarItem={setCurrentSidebarItem}
          submitting={submitting}
          isBlockLocation={isBlockLocation}
        />
      )}
    </>
  );
};
