import { UserData } from 'auth/context/AuthContext';
import { Roles } from '../firebase/interfaces';
import { useGetFhirProviderbyEmailLazyQuery } from 'graphql/generated/remote-schema-hasura';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useCallback, useState } from 'react';
import { ProviderProfileDataOptional } from 'app/my-account/interfaces/profile.interfaces';

const useFhirProviderDataLoader = (
  loggedUser: UserData | null,
  email: string | undefined,
) => {
  const [providerProfileData, setProviderProfileData] =
    useState<ProviderProfileDataOptional | null>(null);
  const [initialProviderProfileData, setInitialProviderProfileData] =
    useState<ProviderProfileDataOptional | null>(null);
  const [updateCount, setUpdateCount] = useState(0);

  const updater = () => setUpdateCount((up) => up + 1);

  const getMfaParts = (mfaPhoneNumber?: string) => {
    try {
      const parsedPhoneNumber = parsePhoneNumber(mfaPhoneNumber || '');
      return {
        mfaPhone: parsedPhoneNumber.nationalNumber,
        mfaCountry: parsedPhoneNumber.countryCallingCode,
      };
    } catch (error) {
      console.error('Unable to parse phone number');
      return {
        mfaPhone: '',
        mfaCountry: '',
      };
    }
  };
  const [fhirGetProviderDataByEmail, { loading: fhirProviderDataLoading }] =
    useGetFhirProviderbyEmailLazyQuery({});

  const fetchData = useCallback(async () => {
    try {
      const { data } = await fhirGetProviderDataByEmail({
        variables: { email: email || '' },
      });
      if (
        loggedUser?.role === Roles.PROVIDER &&
        data &&
        data?.getFHIRProviderbyEmail.providers.length > 0
      ) {
        const user = data.getFHIRProviderbyEmail.providers[0];

        const education = (user?.SENSITIVE_education || []).map(
          (education) => ({
            medicalSchool: education?.medicalSchool,
            residency: education?.residency,
            undergraduate: education?.undergraduate,
          }),
        );

        const provider = {
          firstname: user?.SENSITIVE_firstname || '',
          lastname: user?.SENSITIVE_lastname || '',
          email: user?.SENSITIVE_email || '',
          gender: user?.SENSITIVE_gender || '',
          phone: user?.SENSITIVE_phone || '',
          nationalProviderIdentifier:
            user?.SENSITIVE_national_provider_identifier || '',
          languagesOfCommunication:
            user?.SENSITIVE_languages_of_communication || '',
          credentials: {
            titles: user?.SENSITIVE_credentials.titles,
            states: user?.SENSITIVE_credentials.states,
            certifications: user?.SENSITIVE_credentials.certifications,
          },
          about: user?.SENSITIVE_about || '',
          education: education || '',
          office_information: {
            officeName: user?.SENSITIVE_office_information?.officeName || '',
            email: user?.SENSITIVE_office_information?.email || '',
            phone: user?.SENSITIVE_office_information?.phone || '',
            address1: user?.SENSITIVE_office_information?.address1 || '',
            address2: user?.SENSITIVE_office_information?.address2 || '',
            country: user?.SENSITIVE_office_information?.country || '',
            state: user?.SENSITIVE_office_information?.state || '',
            city: user?.SENSITIVE_office_information?.city || '',
            zipCode: user?.SENSITIVE_office_information?.zipCode || '',
          },
          address_information: {
            address1: user?.SENSITIVE_address_information?.address1 || '',
            address2: user?.SENSITIVE_address_information?.address2 || '',
            country: user?.SENSITIVE_address_information?.country || '',
            state: user?.SENSITIVE_address_information?.state || '',
            city: user?.SENSITIVE_address_information?.city || '',
            zipCode: user?.SENSITIVE_address_information?.zipCode || '',
          },
          signInProvider: loggedUser?.signInProvider || null,
          ...getMfaParts(loggedUser?.mfaPhoneNumber),
          acuityCalendarId: user?.acuityCalendarId || '',
        };
        setProviderProfileData(provider);
        setInitialProviderProfileData(provider);
      }
    } catch (error) {
      console.error('Error fetching FHIR provider data:', error);
    }
  }, [
    email,
    fhirGetProviderDataByEmail,
    loggedUser?.mfaPhoneNumber,
    loggedUser?.role,
    loggedUser?.signInProvider,
    setProviderProfileData,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData, updateCount]);

  return {
    fhirProviderDataLoading,
    providerProfileData,
    initialProviderProfileData,
    setProviderProfileData,
    updater,
  };
};

export default useFhirProviderDataLoader;
