import { DropdownItem } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import { SelectWithFlagsValues } from 'components/inputComponent';
import { SignInProvider } from 'firebase/interfaces';
import {
  Persona_Inquiry_Status_Enum,
  PharmacyInformationInput,
} from 'graphql/generated/hasura';

export interface UserMeasurements {
  weight: string;
  heightFt: string;
  heightIn: string;
  waist: string;
  hips: string;
}

export interface ProfileData
  extends PersonalData,
    ContactData,
    SecurityAndPrivacyData,
    PharmacyData {
  extension?: string;
}

export interface providerDataResponseEducation {
  medicalSchool: string;
  residency: string;
  undergraduate: string;
  id?: number;
}

export interface ProviderProfileDataOptional {
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  nationalProviderIdentifier?: string;
  gender?: string;
  languagesOfCommunication?: DropdownItem[] | string[];
  credentials?: {
    titles: string[];
    states: string[];
    certifications: string[];
  };
  education?: providerDataResponseEducation[];
  office_information?: {
    officeName?: string;
    email?: string;
    phone?: string;
    address1?: string;
    address2?: string;
    country?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
  address_information?: {
    address1?: string;
    address2?: string;
    country?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
  about?: string;
  signInProvider: SignInProvider;
  acuityCalendarId?: string;
}

export interface PersonalData {
  firstname: string;
  lastname: string;
  email: string;
  dob: string;
  userMeasurements: UserMeasurements;
  etnicity: string[];
  gender: string;
  selfIdentifyGender: string;
  genderDescribe?: string;
}

export interface SecurityAndPrivacyData {
  mfaPhone: string;
  mfaCountry: string;
  signInProvider: SignInProvider;
}

export interface PharmacyList {
  address1: string;
  address2?: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
  id: string;
  isDefault: boolean;
  name: string;
  phone: string;
}

export interface PharmacyData {
  pharmacyList?: PharmacyInformationInput[];
}

export interface SecurityAndPrivacyProps extends SecurityAndPrivacyData {
  disableButton: boolean;
  displayMFAForm: boolean;
  displaySecurityAndPrivacy: boolean;
  securityAndPrivacySubmitting: boolean;
  phoneError: string;
  mfaError: string;
  onCodeChange: (code: string) => void;
  onDisplayMFAForm: (displayForm: boolean) => void;
  onDisplaySecurityAndPrivacy: (displaySecurityAndPrivacy: boolean) => void;
  onPhoneNumberChange: (phone: string) => void;
  onVerifyMFACode: () => void;
  onCountryChange: (country: SelectWithFlagsValues) => void;
  onResendCode: () => void;
  onSavePhoneNumber: () => void;
}

export interface PersonalInfoProps extends PersonalData {
  handleUpdatedPersonalInfo: (state: GeneralStates) => void;
  handlePersonalInfoChange: (data: PersonalData) => void;
  userVerificationStatus: Persona_Inquiry_Status_Enum | null | undefined;
}

export interface AddressInformation {
  addressLine1: string;
  addressLine2: string;
  country: string | { label: string; value: string };
  city: string;
  state: string;
  zipCode: string;
}

export interface ContactData {
  phone: string;
  country: string;
  addressInformation: AddressInformation;
  mfaPhoneNumber?: string;
  mfaPhoneCountry?: string;
}

export interface ContactInfoProps extends ContactData {
  handleUpdatedContactInfo: (state: GeneralStates) => void;
  handleContactInfoChange: (data: ContactData) => void;
}

type Locale = Record<string, Record<string, Record<string, string | string[]>>>;

export interface AccountDetailProps {
  locale: Locale;
  formValues: PersonalData;
  setFormValues: (values: PersonalData) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onDateChange: (e: { target: HTMLInputElement }) => void | undefined;
}

interface AccountDetailField {
  label: string;
  type: string;
  placeholder: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  maxLengthValue?: number;
}

type AccountLocale = Record<string, Record<string, Record<string, string>>>;

export interface AccountDetailComponentProps {
  locale: AccountLocale;
  accountDetailsFields: AccountDetailField[];
}

type SecurityAndPrivacyLocale = Record<string, Record<string, string>>;

export interface MultiFactorAuthenticationComponentProps {
  locale: SecurityAndPrivacyLocale;
  country: string;
  countries: {
    code: string | null | undefined;
    flag: string | null | undefined;
  }[];
  phone: string;
  phoneError: string;
  onCountryChange: (country: SelectWithFlagsValues) => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
}

export enum GeneralStates {
  SUCCESS = 'success',
  ERROR = 'error',
  MISSING_INFO = 'missing_info',
}

export interface PharmacyProps extends PharmacyData {
  handleUpdatedPharmacyInfo: (state: GeneralStates) => void;
  handlePharmacyInfoChange: (data: PharmacyData) => void;
}
