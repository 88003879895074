import { Route, Routes } from 'react-router-dom';
import { BackOfficeRoutes } from 'backoffice/routes/BackOfficeRoutes';
import { LegalDocumentRoutes } from 'legalDocument/routes/LegalDocumentRoutes';
import { AppRoutes } from 'app/routes/AppRoutes';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { Action } from 'auth/pages/Action/Action';
import {
  AUTH_ROUTES,
  AUTH_SIGN_UP_PROVIDER,
  BACK_OFFICE_ROUTES,
  LEGAL_DOCUMENTS_ROUTES,
  LOGOUT,
  MFA_ACTION,
  NOT_FOUND_ROUTE,
} from 'utilities/routes';
import { UnauthenticatedRoute } from './UnauthenticatedRoute';
import { AuthRoutes, ContentSize } from 'auth/routes/AuthRoutes';
import NetworkRequiredRoutes from 'components/networkRequiredRoutes';
import AuthLayout from 'layout/AuthLayout';
import { SignUpProvider } from 'auth/pages/SignUpProvider/SignUpProvider';
import { NotVerifiedEmailRoute } from './NotVerifiedEmailRoute';
import RoleRequiredRoute from 'components/RoleRequiredRoute';
import { Roles } from '../firebase/interfaces';
import Logout from './Logout';

export const AppRouter = () => {
  return (
    <Routes>
      <Route
        path={MFA_ACTION}
        element={
          <NetworkRequiredRoutes>
            <Action />
          </NetworkRequiredRoutes>
        }
      />

      <Route
        path={AUTH_ROUTES}
        element={
          <UnauthenticatedRoute>
            <AuthRoutes />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path={BACK_OFFICE_ROUTES}
        element={
          <AuthenticatedRoute>
            <BackOfficeRoutes />
          </AuthenticatedRoute>
        }
      />

      <Route path={LEGAL_DOCUMENTS_ROUTES} element={<LegalDocumentRoutes />} />
      <Route path={NOT_FOUND_ROUTE} element={<AppRoutes />} />
      <Route path={LOGOUT} element={<Logout />} />

      <Route
        path={AUTH_SIGN_UP_PROVIDER}
        element={
          <NotVerifiedEmailRoute>
            <AuthLayout
              background="bg-login-m bg-cover desktop:bg-login"
              footerType="default"
              contentSize={ContentSize.FULL_WIDTH}
            >
              <NetworkRequiredRoutes>
                <RoleRequiredRoute
                  allowedRoles={[Roles.PROVIDER]}
                  notFullProviderDataRequired
                >
                  <SignUpProvider />
                </RoleRequiredRoute>
              </NetworkRequiredRoutes>
            </AuthLayout>
          </NotVerifiedEmailRoute>
        }
      />
    </Routes>
  );
};
