import { phoneNumberPattern } from 'utilities/variables';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import { parseMappedCountriesIntoSelectValues } from 'utilities/functions';
import React, { useMemo } from 'react';
import ErrorMessageWithIcon from 'components/errorMessageWithIcon';

interface MfaPhoneInputComponentProps {
  country: string;
  countries: {
    code: string | null | undefined;
    flag: string | null | undefined;
  }[];
  onCountryChange: (country: SelectWithFlagsValues) => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  phoneNumber: string;
  currentPhoneNumber?: string;
  children?: React.ReactNode;
  phoneError?: string;
}

export const MfaPhoneInputComponent: React.FC<MfaPhoneInputComponentProps> = ({
  countries,
  onCountryChange,
  onPhoneNumberChange,
  country,
  phoneNumber,
  children,
  phoneError,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MULTI_FACTOR_AUTH,
  });
  const parseSelectedValue = (
    currentCountry: string,
  ): SelectWithFlagsValues | undefined => {
    const values = parseMappedCountriesIntoSelectValues(countries);
    const matchingValue = values.find((item) => item.value === currentCountry);

    if (
      matchingValue &&
      matchingValue.label &&
      matchingValue.value &&
      matchingValue.flag
    ) {
      return {
        label: matchingValue.label,
        value: matchingValue.value,
        flag: matchingValue.flag,
      };
    } else {
      return undefined;
    }
  };

  const getMemoizedDefaultParsedValue = useMemo(
    () => parseMappedCountriesIntoSelectValues(countries)[0],
    [countries],
  );

  const getMemoizedParsedCountries = useMemo(
    () => parseMappedCountriesIntoSelectValues(countries),
    [countries],
  );

  if (loading && !locale) return null;

  return (
    <>
      <div className="flex flex-col items-left">
        <label
          className="block text-base text-btn-black font-semibold desktop:justify-self-center"
          htmlFor="phoneNumber"
        >
          {locale?.mfaSMS.phoneLabel}
          <span className="text-base font-semibold text-clc-red">
            {locale?.mfaSMS.required}
          </span>
        </label>

        <div className="flex flex-col desktop:flex-row gap-2.5">
          <div className="flex flex-col">
            <div className="flex flex-row w-full desktop:flex-grow">
              <InputComponent
                testID="phoneNumber-country-select"
                type="select-with-country-icons"
                name="country"
                borderRadiusRight={true}
                errorStatus={!!phoneError}
                selectInputWithFlagsProps={{
                  currentValue: parseSelectedValue(country),
                  defaultValue:
                    getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                  options:
                    getMemoizedParsedCountries as SelectWithFlagsValues[],
                  onSelectChange: (e) =>
                    onCountryChange(e as SelectWithFlagsValues),
                }}
              />

              <InputComponent
                testID="phoneNumber-input"
                disableTelWidth={true}
                type="tel"
                pattern={phoneNumberPattern}
                name="phoneNumber"
                errorStatus={!!phoneError}
                onChange={(e) => {
                  return onPhoneNumberChange(e.target.value);
                }}
                borderRadiusLeft={true}
                value={phoneNumber}
                placeholder={locale?.mfaSMS.phoneInputPlaceholder}
                customInputClassname="h-[46px] !w-full !font-medium"
                maxLengthValue={10}
                noMarginBottom
              />
            </div>
            {!!phoneError && <ErrorMessageWithIcon message={phoneError} />}
          </div>
          {children}
        </div>
      </div>
    </>
  );
};
