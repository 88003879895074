import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Enum_Legaldocument_Legaldocumenttype } from 'graphql/generated/strapi';
import { useGetLegalDocuments } from 'hooks/legalDocuments/useGetLegalDocuments';
import { ReactComponent as InfoIcon } from 'assets/icons/info-gray.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/googleIcon.svg';
import { ReactComponent as AppleIcon } from 'assets/icons/appleIcon.svg';
import {
  useAppleAuthProviderSignInPopUp,
  useGoogleAuthProviderSignInPopUp,
  useGoogleSignupWithCredentialsForProvider,
} from '../../../firebase/hooks';
import { emailPattern, passwordPattern } from 'utilities/variables';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';

import ButtonComponent from 'components/button/buttonComponent';
import LegalTerms from 'components/LegalTerms';
import AlertComponent from 'components/alertComponent';
import useFormValidation, { ValidationRules } from 'hooks/useFormValidation';
import { AUTH_SEND_VERIFICATION_EMAIL } from 'utilities/routes';
import InputComponent from 'components/inputComponent';
import ErrorMessageWithIcon from 'components/errorMessageWithIcon';

export type ProviderData = {
  email: string;
  password: string;
  confirmPassword: string;
  isChecked: boolean;
};

export const CreateProvider = () => {
  const navigate = useNavigate();

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.CREATE_PROVIDER,
  });

  const { data: fireBaseErrorsLocale } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });

  const validationRules: ValidationRules = {
    email: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(emailPattern).test(value),
        message: locale?.invalidEmail,
      },
    ],
    password: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(passwordPattern).test(value),
        message: locale?.passwordNotValid,
      },
    ],
    confirmPassword: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
      {
        validator: (value, formData) => value === formData?.password,
        message: locale?.confirmPasswordMismatch,
      },
    ],
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { errors, validateForm } = useFormValidation(validationRules);

  const [userData, setUserData] = useState<ProviderData>({
    email: '',
    password: '',
    confirmPassword: '',
    isChecked: false,
  });

  const isSubmitTriggered = useRef<boolean>(false);

  const { legalDocuments } = useGetLegalDocuments({
    locale: 'en',
    legalDocumentTypes: [
      Enum_Legaldocument_Legaldocumenttype.TermsOfService,
      Enum_Legaldocument_Legaldocumenttype.PrivacyPolicy,
    ],
  });

  const [registeredUser, firebaseError, , , handleGoogleSignupWithCredentials] =
    useGoogleSignupWithCredentialsForProvider();

  const [, , handleGoogleSignInWithPopUp] = useGoogleAuthProviderSignInPopUp();
  const [, , handleAppleSignInWithPopUp] = useAppleAuthProviderSignInPopUp();

  const handleGoogleSignIn = () =>
    handleGoogleSignInWithPopUp(legalDocuments || []);
  const handleAppleSignIn = () =>
    handleAppleSignInWithPopUp(legalDocuments || []);

  const handleValidation = (userData: ProviderData) => {
    const formData = JSON.parse(JSON.stringify(userData));
    delete formData.isChecked;
    return validateForm(formData);
  };

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const fieldName = e.target.name;
    const nextData = { ...userData, [fieldName]: value };
    setUserData(nextData);

    if (isSubmitTriggered.current) {
      handleValidation(nextData);
    }
  };

  const handleCheckboxChange = (checked: boolean) => {
    setIsCheckboxChecked(checked);
  };

  const handleSignup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    isSubmitTriggered.current = true;

    const isFormValid = handleValidation(userData);

    if (!isCheckboxChecked) {
      return;
    }

    if (!isFormValid) {
      return;
    }

    handleGoogleSignupWithCredentials(userData.email, userData.password);
  };

  useEffect(() => {
    if ('token' in registeredUser && registeredUser.token) {
      navigate(AUTH_SEND_VERIFICATION_EMAIL, {
        replace: true,
        state: { isProvider: true },
      });
    }
  }, [registeredUser, firebaseError, navigate]);

  if (loading && !locale) return null;

  return (
    <div className="bg-transparent overflow-hidden shadow desktop:rounded-lg desktop:mb-0 desktop:p-0 desktop:w-full desktop:max-w-screen-xl desktop:mx-auto">
      <form onSubmit={handleSignup} noValidate>
        <div className="w-full bg-white bg-border rounded-t-lg desktop:basis-3/5">
          <div className="flex flex-col gap-2.5 desktop:flex-row desktop:items-center desktop:justify-between px-5 py-5 desktop:px-[60px] desktop:py-[20.5px]">
            <h3 className="text-h4 text-charcoal-gray font-semibold">
              {locale?.title}
            </h3>
            <p className="text-sm text-clc-red font-bold">
              {locale?.requiredText}
            </p>
          </div>

          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />

          <div className="flex flex-col gap-2 px-5 py-[30px] desktop:px-[60px] desktop:pt-[30px]">
            <div className="flex flex-col items-start desktop:items-center">
              <label
                className="w-full text-base text-btn-black font-semibold pr-2.5"
                htmlFor="email"
              >
                {locale?.emailLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale?.required}
                </span>
              </label>
              <div className="w-full">
                <InputComponent
                  testID="email-input"
                  type="email"
                  name="email"
                  onChange={handleOnInputChange}
                  errorStatus={
                    !!errors.email ||
                    firebaseError === fireBaseErrorsLocale?.emailInUse
                  }
                  errorMsgWithIcon={
                    errors.email ||
                    (firebaseError === fireBaseErrorsLocale?.emailInUse
                      ? firebaseError
                      : '')
                  }
                  placeholder={locale?.emailPlaceholder}
                  customInputClassname="!font-medium"
                  noMarginBottom
                  reservePlaceForErrorMessage
                />
              </div>
            </div>

            <div className="flex flex-col items-start">
              <label
                className="w-full text-base text-btn-black font-semibold pr-2.5"
                htmlFor="password"
              >
                {locale?.passwordLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale?.required}
                </span>
              </label>
              <div className="w-full">
                <InputComponent
                  testID="password-input"
                  type="password"
                  name="password"
                  onChange={handleOnInputChange}
                  errorStatus={!!errors.password}
                  errorMsgWithIcon={errors.password}
                  maxLengthValue={64}
                  placeholder={locale?.passwordPlaceholder}
                  customInputClassname="!font-medium"
                  noMarginBottom
                />
                {!errors.password && (
                  <div className="mt-1 flex gap-[5px]">
                    <InfoIcon className="shrink-0" />
                    <p className="text-xs text-med-gray-3 font-semibold">
                      {locale?.passwordNotValid}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start">
              <label
                className="w-full text-base text-btn-black font-semibold pr-2.5"
                htmlFor="password"
              >
                {locale?.confirmPasswordLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale?.required}
                </span>
              </label>
              <div className="w-full">
                <InputComponent
                  testID="confirm-password-input"
                  type="password"
                  name="confirmPassword"
                  onChange={handleOnInputChange}
                  errorStatus={!!errors.confirmPassword}
                  errorMsgWithIcon={errors.confirmPassword}
                  maxLengthValue={64}
                  placeholder={locale?.confirmPasswordPlaceholder}
                  customInputClassname="!font-medium"
                  noMarginBottom
                  reservePlaceForErrorMessage
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />

        <div className="w-full bg-white bg-border desktop:basis-3/5">
          <div className="flex flex-col gap-[30px] px-5 pt-5 pb-10 desktop:px-[60px]">
            <div className="flex flex-row gap-2.5 items-start">
              <InputComponent
                testID="term-and-conditions-input"
                type="checkbox"
                name="agreementsCheckbox"
                errorStatus={!isCheckboxChecked && isSubmitTriggered.current}
                checkboxProps={{
                  checked: isCheckboxChecked,
                  onCheckboxChange: handleCheckboxChange,
                }}
              />
              <div className="flex flex-col mt-[5px]">
                <label
                  className="text-med-gray text-sm font-semibold"
                  htmlFor="agreementsCheckbox"
                >
                  <LegalTerms
                    linkClasses="text-clc-blue"
                    legalDocuments={legalDocuments || []}
                    openingLabel={locale?.termsConditionsLabel}
                    separatorLabel={locale?.termsConditionsLabel2}
                    closingLabel={locale?.termsConditionsLabel3}
                  />
                </label>
                {!isCheckboxChecked && isSubmitTriggered.current && (
                  <ErrorMessageWithIcon message={locale?.termsError} />
                )}
              </div>
            </div>

            <div className="flex justify-center">
              <ButtonComponent
                testID="signup-button"
                type="submit"
                paddingX="px-10"
                paddingY="py-[9.5px] desktop:py-[9px]"
                className="!w-full text-sm rounded !border"
              >
                {locale?.signupButton}
              </ButtonComponent>
            </div>
            <div className="relative">
              <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
              <div className="absolute left-1/2 -translate-x-1/2 -top-[12px] px-[20px] bg-white">
                <p className="text-base font-semibold text-med-gray-3">
                  {locale?.OR}
                </p>
              </div>
            </div>
            <div className="flex flex-col desktop:flex-row gap-2.5">
              <ButtonComponent
                testID="google-signup"
                onClick={handleGoogleSignIn}
                type="social"
                iconPosition="left"
                Icon={GoogleIcon}
                className="flex justify-center desktop:w-full text-sm !px-0 rounded hover:bg-hover-blue !flex-grow"
                containerClassName="!justify-start !text-charcoal-gray text-exo"
                stroke="transparent"
                iconWidth="w-[28px]"
                iconHeight="h-[28px]"
                paddingY="py-[5px]"
              >
                {locale?.signupGoogle}
              </ButtonComponent>
              <ButtonComponent
                testID="apple-signup"
                onClick={handleAppleSignIn}
                type="social"
                iconPosition="left"
                Icon={AppleIcon}
                className="flex justify-center desktop:w-full text-sm !px-0 rounded hover:bg-hover-blue !flex-grow"
                containerClassName="!justify-start !text-charcoal-gray text-exo"
                stroke="transparent"
                iconWidth="w-[28px]"
                iconHeight="h-[28px]"
                paddingY="py-[5px]"
              >
                {locale?.signupApple}
              </ButtonComponent>
            </div>

            {firebaseError &&
              firebaseError !== fireBaseErrorsLocale?.emailInUse && (
                <AlertComponent type="warning" text={firebaseError} />
              )}
          </div>
        </div>
      </form>
      <div className="bg-clc-blue/75 rounded-b-lg">
        <h5 className="text-h5 desktop:text-h4 text-center px-10 py-[17px] text-white font-semibold">
          {locale?.alreadyAccountLabel}{' '}
          <Link className="underline" to="/auth/login">
            {locale?.signInHereLink}
          </Link>
        </h5>
      </div>
    </div>
  );
};
