import { useEffect } from 'react';
import { useRef } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import {
  useFirebaseSignOut,
  useVerifyActionCode,
  useVerifyPasswordResetCode,
} from '../../../firebase/hooks';
import {
  AUTH_LOGIN,
  AUTH_MFA_SETUP,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_PASSWORD_EXPIRED_LINK,
  AUTH_SEND_VERIFICATION_EMAIL,
  AUTH_SIGN_UP_PROVIDER,
} from 'utilities/routes';

type ModeActionType = {
  oobCode: string;
  navigate: NavigateFunction;
  handleVerifyActionCode: (oobCode: string) => Promise<boolean>;
  callback: () => Promise<unknown>;
  handleVerifyPasswordResetCode: (oobCode: string) => Promise<boolean>;
  email: string;
  continueUrl: string;
};

enum MODE {
  RESET_PASSWORD = 'resetPassword',
  VERIFY_EMAIL = 'verifyEmail',
  REVERT_SECONDARY_FACTORY = 'revertSecondFactorAddition',
}

const getUserEmail = (url: string) => {
  return new URL(url)?.search?.split('email=')?.[1] || '';
};

const modeActions = {
  [MODE.RESET_PASSWORD]: async ({
    oobCode,
    navigate,
    handleVerifyPasswordResetCode,
    email,
  }: ModeActionType) => {
    const result = await handleVerifyPasswordResetCode(oobCode);

    result
      ? navigate(`${AUTH_RESET_PASSWORD}?oobCode=${oobCode}`, {
          replace: true,
        })
      : navigate(AUTH_RESET_PASSWORD_EXPIRED_LINK, {
          replace: true,
          state: {
            email,
          },
        });
  },
  [MODE.VERIFY_EMAIL]: async ({
    oobCode,
    navigate,
    handleVerifyActionCode,
    continueUrl,
  }: ModeActionType) => {
    const result = await handleVerifyActionCode(oobCode);

    if (!result) {
      return navigate(`${AUTH_SEND_VERIFICATION_EMAIL}?linkExpired=true`, {
        replace: true,
      });
    }

    if (continueUrl.includes(AUTH_SIGN_UP_PROVIDER)) {
      return navigate(AUTH_SIGN_UP_PROVIDER, { replace: true });
    }

    return navigate(AUTH_MFA_SETUP, { replace: true });
  },
  [MODE.REVERT_SECONDARY_FACTORY]: async ({
    oobCode,
    navigate,
    handleVerifyActionCode,
    callback,
  }: ModeActionType) => {
    const result = await handleVerifyActionCode(oobCode);
    await callback();
    navigate(
      result
        ? `${AUTH_LOGIN}?mfaRemoved=true`
        : `${AUTH_LOGIN}?mfaLinkExpired=true`,
      { replace: true },
    );
  },
};

const getAction = (mode: MODE | null | undefined) => {
  if (!mode) return null;
  return modeActions[mode];
};

export const Action = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode') as MODE;
  const oobCode = queryParams.get('oobCode') || '';
  const continueUrl = queryParams.get('continueUrl') || '';
  const email = continueUrl ? getUserEmail(continueUrl) : '';
  const actionChecked = useRef<boolean>(false);
  const [, handleVerifyActionCode] = useVerifyActionCode();
  const [handleVerifyPasswordResetCode] = useVerifyPasswordResetCode();
  const signOutUser = useFirebaseSignOut();

  useEffect(() => {
    const checkActions = getAction(mode);

    if (!actionChecked.current && !!checkActions) {
      checkActions({
        oobCode,
        navigate,
        handleVerifyActionCode,
        callback: signOutUser,
        handleVerifyPasswordResetCode,
        email,
        continueUrl,
      });
      actionChecked.current = true;
    }
  }, [
    mode,
    oobCode,
    navigate,
    handleVerifyActionCode,
    signOutUser,
    handleVerifyPasswordResetCode,
    email,
    continueUrl,
  ]);

  return null;
};
