import {
  pageIds,
  questionnairesIds,
  unauthenticatedQuestionnairesIds,
} from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useGetUnauthenticatedQuestionnaireByIdQuery } from 'graphql/generated/hasura';

import DynamicQuestionnaire from 'components/dynamicQuestionnaire/DynamicQuestionnaireComponent';
import { DynamicQuestionnaireResponse } from 'components/dynamicQuestionnaire/interfaces/dynamicQuestionnaireResponse.interface';
import { useNavigate } from 'react-router-dom';
import { useModalParams } from 'components/modal/useModalManager';
import { NpiInfo } from './components/NpiInfo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';

export const SignUpProvider = () => {
  const { enableSignupProvider } = useFlags<CodexFeatureFlags>();
  const navigate = useNavigate();
  const { isOpen: isNpiInfoOpen } = useModalParams('npi-info');

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.SIGN_UP,
  });

  const {
    data: unauthenticatedQuestionnaireData,
    loading: loadingQuestionnaire,
  } = useGetUnauthenticatedQuestionnaireByIdQuery({
    variables: {
      questionnaire_id: questionnairesIds.SIGN_UP_PROVIDER,
    },
  });

  const preProcessQuestionnaireData = () => {
    const tabs =
      unauthenticatedQuestionnaireData?.unauthenticated_questionnaires[0]
        .data ?? [];

    const processedObject: DynamicQuestionnaireResponse = {
      __typename: 'questionnaires',
      data: [tabs[0], tabs[1], tabs[2], tabs[3], tabs[4], tabs[5]],
      title:
        unauthenticatedQuestionnaireData?.unauthenticated_questionnaires[0]
          .title ?? '',
      subtitle:
        unauthenticatedQuestionnaireData?.unauthenticated_questionnaires[0]
          .subtitle,
      questionnaires_questionnaire_questions:
        unauthenticatedQuestionnaireData?.unauthenticated_questionnaires[0].unauthenticated_questionnaire_questions.map(
          (item) => {
            return {
              questionnaire_questions_question: {
                ...item.unauthenticated_questionnaire_questions_unauthenticated_question,
                __typename: 'questions',
              },
            };
          },
        ) as [],
    };

    return processedObject;
  };

  const processedData = preProcessQuestionnaireData();

  if (loading || !locale) return null;

  if (enableSignupProvider === false) {
    navigate('/');
  }

  if (
    (loading && !locale) ||
    (loadingQuestionnaire && !unauthenticatedQuestionnaireData)
  )
    return null;

  return (
    <>
      {isNpiInfoOpen && <NpiInfo />}
      <div className="rounded-[5px] desktop:mt-16 bg-base-content overflow-hidden desktop:mb-0 desktop:p-0 desktop:w-full desktop:h-full desktop:mx-auto">
        <div className="desktop:min-h-content-page-web desktop:px-[135px] pt-20 pb-20">
          {unauthenticatedQuestionnaireData && processedData && (
            <DynamicQuestionnaire
              id={unauthenticatedQuestionnairesIds.SIGN_UP_PROVIDER}
              response={processedData}
              enableSubmitCheckbox={{ enable: false }}
              isUnauthenticatedQuestionnaire={true}
            />
          )}
        </div>
      </div>
    </>
  );
};
