import { useContext, useEffect, useState } from 'react';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import { useSendVerificationEmail } from '../../../firebase/hooks';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTH_LOGIN } from 'utilities/routes';
import { ReactComponent as BallCheckIcon } from 'assets/icons/ball-check-icon.svg';
import { ReactComponent as BigWarningIcon } from 'assets/icons/big-warning.svg';
import Alert from 'components/alertComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { useTimer } from 'hooks/useTimer';
import { SECONDS_IN_MIN } from 'utilities/constants/time';
import { interpolateVariables } from 'utilities/functions';

const formatCounter = (counter: number) => {
  const farmattedCounter = `0${counter}`.slice(-2);
  return `00:${farmattedCounter}`;
};

export const SendVerificationEmail = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.SEND_VERIFICATION_EMAIL,
  });
  const { data: firebaseLocale, loading: firebaseLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const linkExpired = queryParams.get('linkExpired') || '';
  const accountNotVerified = queryParams.get('accountNotVerified') || '';
  const isProvider = location.state?.isProvider ?? false;
  const { user } = useContext<AuthContextType>(AuthContext);
  const [errorMsg, handleSendVerificationEmail] = useSendVerificationEmail();
  const [resentEmailError, setResentEmailError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const {
    timeLeft,
    temporalyDisableResendActions,
    setTimeLeft,
    setTemporalyDisableResendActions,
  } = useTimer();

  const handleResendVerificationEmail = async () => {
    const result = await handleSendVerificationEmail(isProvider);
    setTemporalyDisableResendActions(true);
    setTimeLeft(SECONDS_IN_MIN);
    result ? setResentEmailError(false) : setResentEmailError(true);
  };

  const getPageBody = () => {
    if (linkExpired) {
      return (
        <div className="w-full flex flex-col items-center">
          <p>{locale?.expiredText1}</p>
          <p>{locale?.expiredText2}</p>
        </div>
      );
    }

    return (
      <span>
        {interpolateVariables(locale?.sentText, {
          email,
        })}
      </span>
    );
  };

  const getSendAgainBlock = () => {
    if (linkExpired) {
      return (
        <ButtonComponent
          onClick={handleResendVerificationEmail}
          className="!text-sm !rounded !border"
        >
          {locale?.resendButton}
        </ButtonComponent>
      );
    }

    return (
      <p className="text-center">
        <span className="text-base font-medium text-med-gray-3">
          {locale?.didntGetEmail}
        </span>
        <ButtonComponent
          type="underline"
          className="!text-sm !font-bold !w-auto"
          onClick={handleResendVerificationEmail}
        >
          {locale?.resendButton}
        </ButtonComponent>
      </p>
    );
  };

  useEffect(() => {
    if (linkExpired) return;

    setTemporalyDisableResendActions(true);
    setTimeLeft(SECONDS_IN_MIN);
  }, [setTemporalyDisableResendActions, setTimeLeft, linkExpired]);

  useEffect(() => {
    if (user?.email) {
      setEmail(user?.email);
    }
  }, [user, setEmail]);

  if ((loading && !locale) || (firebaseLoading && !firebaseLocale)) return null;

  return (
    <div className="flex flex-col gap-2.5 w-full mb-2.5">
      {resentEmailError && (
        <Alert
          type="warning"
          testID="resent-email-error-alert"
          text={errorMsg}
        />
      )}

      {accountNotVerified && !linkExpired && (
        <Alert type="warning" text={locale?.emailNotVerified} />
      )}
      <div className="flex flex-col items-center desktop:justify-center px-[20px] py-[30px] desktop:px-[60px] desktop:py-[80px] gap-[30px] rounded-lg bg-white">
        <div className="flex flex-col items-center desktop:justify-center gap-[10px]">
          {linkExpired ? <BigWarningIcon /> : <BallCheckIcon />}
          <p className="text-h1 font-medium text-btn-black text-center">
            {linkExpired ? locale?.expiredTitle : locale?.sentTitle}
          </p>
          <p className="text-base font-medium text-btn-black text-center">
            {getPageBody()}
          </p>
        </div>
        {temporalyDisableResendActions ? (
          <p className="text-base font-medium text-med-gray-3 text-center">
            {locale?.anotherResetAvailable}
            <span className="w-[46px] font-bold text-btn-black">
              {formatCounter(timeLeft)}
            </span>
          </p>
        ) : (
          getSendAgainBlock()
        )}

        <ButtonComponent
          onClick={() => navigate(AUTH_LOGIN)}
          type="underline"
          className="!text-sm !font-bold"
        >
          {locale?.loginButton}
        </ButtonComponent>
      </div>
    </div>
  );
};
