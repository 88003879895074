import Alert from 'components/alertComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetPage } from 'hooks/useGetPage';
import { useTimer } from 'hooks/useTimer';
import { useEffect } from 'react';
import {
  genericActionsIds,
  pageIds,
  questionnairesIds,
} from 'utilities/constants';

const SHOW_SECONDS = 5;

interface DynamicQuestionnairePrefaceProps {
  title: string;
  subtitle: string | null | undefined;
  id: number;
}

const DynamicQuestionnairePreface: React.FC<
  DynamicQuestionnairePrefaceProps
> = ({ title, subtitle, id }) => {
  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.REQUIRED],
    });

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.SIGN_UP,
  });

  const {
    temporalyDisableResendActions,
    setTimeLeft,
    setTemporalyDisableResendActions,
  } = useTimer();

  useEffect(() => {
    setTemporalyDisableResendActions(true);
    setTimeLeft(SHOW_SECONDS);
  }, [setTemporalyDisableResendActions, setTimeLeft]);

  if (!genericAction || genericActionLoading || loading) return null;

  return (
    <div className="w-full px-7">
      {id === questionnairesIds.SIGN_UP_PROVIDER &&
      temporalyDisableResendActions ? (
        <Alert type="positive" text={locale?.successfulEmailConfirmation} />
      ) : (
        <div className="flex flex-col gap-[30px] desktop:flex-row desktop:items-center desktop:justify-between">
          <h1 className="text-h2 desktop:text-h1 text-charcoal-gray font-medium">
            {title}
          </h1>
          {id !== questionnairesIds.SIGN_UP_PROVIDER && (
            <p className="text-sm text-clc-red font-bold">
              {genericAction?.[genericActionsIds.REQUIRED].required}
            </p>
          )}
        </div>
      )}

      <p className="mt-[20px] text-h6 text-med-gray-3 desktop:flex mobile:hidden">
        {subtitle}
      </p>
    </div>
  );
};

export default DynamicQuestionnairePreface;
