import { useBlocker } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

export enum BlockerState {
  BLOCKED = 'blocked',
  UNBLOCKED = 'unblocked',
  PROCEEDING = 'proceeding',
}

export interface INavigateBlock {
  isBlock: boolean;
}

export const useNavigateBlock = (isBlock: boolean) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const skipBlocker = nextLocation.state?.skipBlocker;

    return (
      isBlock &&
      currentLocation.pathname !== nextLocation.pathname &&
      !skipBlocker
    );
  });

  const handleCancel = useCallback(
    () => (blocker.reset ? blocker.reset() : {}),
    [blocker],
  );

  const handleConfirm = useCallback(() => {
    blocker.proceed && blocker.proceed();
  }, [blocker]);

  const unloadHandler = useCallback((event: Event) => {
    event.preventDefault();

    return ''; // Legacy method for cross browser support
  }, []);

  useEffect(() => {
    if (!isBlock) return;

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [isBlock, unloadHandler]);

  return {
    handleCancel,
    handleConfirm,
    blocker,
  };
};
