import DOMPurify from 'dompurify';

export const sanitize = (str: string): string => {
  return DOMPurify.sanitize(str);
};

export function boldString(str: string, find: string) {
  if (!find || !str) return str;
  const escapedFind = find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const re = new RegExp(escapedFind, 'gi');
  return str.replace(re, '<b>$&</b>');
}
