import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';

export const SavedLabel = () => {
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SIGN_UP_PROVIDER_MODULE,
  });

  return (
    <div className="border px-2.5 border-green-600 rounded-3xl text-sm font-semibold text-green-600 bg-green-50">
      {locale?.saved}
    </div>
  );
};
