import { twMerge } from 'tailwind-merge';

interface CarouselArrowProps {
  onClick: () => void;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label?: string;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
}

const CarouselArrow: React.FC<CarouselArrowProps> = ({
  onClick,
  icon: Icon,
  label,
  iconPosition = 'left',
  disabled,
}) => (
  <div
    className={twMerge(
      'flex flex-row items-center p-0 cursor-pointer',
      disabled && 'opacity-50 pointer-events-none',
    )}
    onClick={onClick}
  >
    {iconPosition === 'left' && <Icon className="w-[6.25px] fill-current" />}

    {label && (
      <span className="text-sm ml-2 underline uppercase text-clc-blue font-bold">
        {label}
      </span>
    )}

    {iconPosition === 'right' && (
      <Icon className="w-[6.25px] fill-current ml-2" />
    )}
  </div>
);

export default CarouselArrow;
