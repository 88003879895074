import React, { useEffect, useState } from 'react';

type ToggleTypes = 'default' | 'large';

interface ToggleProps {
  checked?: boolean;
  type?: ToggleTypes;
  labelLeft: string;
  labelRight: string;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({
  checked = false,
  type = 'default',
  labelLeft,
  labelRight,
  disabled = false,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  let containerClass;
  let ballClass;
  let translateClass;
  const disabledClass = 'disabled opacity-50 !cursor-not-allowed';

  switch (type) {
    case 'large':
      containerClass = 'w-[100px] w-9';
      ballClass = `w-[26px] h-[26px] ${isChecked ? 'bg-white' : 'bg-med-gray'}`;
      translateClass = 'translate-x-16';
      break;

    default:
      containerClass = 'desktop:w-[46px] desktop:h-[28px] w-[100px] w-9';
      ballClass = `desktop:w-[18px] desktop:h-[18px] w-[26px] h-[26px] ${
        isChecked ? '!bg-white' : '!bg-med-gray-3'
      }`;
      translateClass = 'desktop:translate-x-[19px] translate-x-16';
      break;
  }

  const handleToggle = () => {
    const newChecked = !isChecked;
    if (disabled) {
      return;
    }
    setIsChecked(newChecked);
    onChange(newChecked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <>
      <p
        className={`text-sm  font-bold mr-[5px] ${
          !isChecked ? 'text-clc-blue' : 'text-neutral-gray'
        } ${disabled && disabledClass}`}
      >
        {labelLeft}
      </p>
      <div
        className={`${containerClass} p-0.5 border border-solid border-med-gray-3 rounded-full cursor-pointer transition-colors ${
          disabled && disabledClass
        } ${
          isChecked ? 'border-clc-blue bg-clc-blue' : 'border-med-gray bg-white'
        }`}
      >
        <div
          className={`w-full p-0.5 rounded-full transition-colors ${
            isChecked ? 'bg-clc-blue' : 'bg-white'
          }`}
          onClick={handleToggle}
        >
          <div
            className={`${ballClass} transform transition-transform ${
              isChecked ? translateClass : 'translate-x-0'
            } rounded-full bg-white shadow`}
          />
        </div>
      </div>
      <p
        className={`text-sm  font-bold ml-[5px] ${
          isChecked ? 'text-clc-blue' : 'text-neutral-gray'
        } ${disabled && disabledClass}`}
      >
        {labelRight}
      </p>
    </>
  );
};

export default Toggle;
