import React, { useEffect } from 'react';
import { useFirebaseSignOut } from '../firebase/hooks';

const Logout = () => {
  const signOut = useFirebaseSignOut();
  useEffect(() => {
    signOut();
  }, [signOut]);

  return <div />;
};

export default Logout;
