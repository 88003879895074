import { MfaPhoneInputComponent } from './MfaPhoneInputComponent';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { SelectWithFlagsValues } from 'components/inputComponent';

interface MfaSmsComponentProps {
  country: string;
  countries: {
    code: string | null | undefined;
    flag: string | null | undefined;
  }[];
  onCountryChange: (country: SelectWithFlagsValues) => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  phoneNumber: string;
  currentPhoneNumber?: string;
  marginBottom?: string;
  children?: React.ReactNode;
  phoneError?: string;
}

const MfaSmsComponent: React.FC<MfaSmsComponentProps> = ({
  country,
  countries,
  onCountryChange,
  onPhoneNumberChange,
  phoneNumber,
  currentPhoneNumber,
  marginBottom = 'mb-5',
  children,
  phoneError,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MULTI_FACTOR_AUTH,
  });

  if (loading && !locale) return null;

  return (
    <div
      className={`rounded ${marginBottom} mobile:px-0 w-full desktop:w-[450px] desktop:max-w-[450px] mx-auto`}
    >
      <MfaPhoneInputComponent
        country={country}
        countries={countries}
        onCountryChange={onCountryChange}
        onPhoneNumberChange={onPhoneNumberChange}
        phoneNumber={phoneNumber}
        currentPhoneNumber={currentPhoneNumber}
        phoneError={phoneError}
      >
        {children}
      </MfaPhoneInputComponent>
    </div>
  );
};

export default MfaSmsComponent;
