import React, { useCallback } from 'react';
import Modal from './modal/modalComponent';
import { useGetGenericActions } from '../hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from '../utilities/constants';
import { ModalButtonProps } from './modal/modal.interfaces';
import { useGetPage } from '../hooks/useGetPage';
import { useFirebaseSignOut } from '../firebase/hooks';
import { AUTH_LOGIN } from '../utilities/routes';
import {
  BlockerState,
  INavigateBlock,
  useNavigateBlock,
} from '../hooks/useNavigateBlock';

const NavigateBlockModal: React.FC<INavigateBlock> = ({ isBlock }) => {
  const signOutUser = useFirebaseSignOut();

  const {
    handleConfirm: handleContinue,
    handleCancel,
    blocker,
  } = useNavigateBlock(isBlock);

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL, genericActionsIds.LEAVE],
    });

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.LEAVE_PAGE_MODAL,
  });

  const handleConfirm = useCallback(() => {
    if (blocker.location?.pathname === AUTH_LOGIN) {
      signOutUser();
    }

    handleContinue();
  }, [blocker, handleContinue, signOutUser]);

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.LEAVE]?.leave,
      onClick: handleConfirm,
      className: 'desktop:w-[160px] text-sm font-bold  rounded',
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL]?.cancel,
      onClick: handleCancel,
      type: 'underline',
      className: 'text-sm font-bold ',
    },
  ];

  if ((loading && !locale) || (genericActionLoading && !genericAction)) {
    return null;
  }

  return (
    <Modal
      isOpen={blocker.state === BlockerState.BLOCKED}
      onClose={handleCancel}
      buttons={Buttons}
      modalContainerCustomClass="desktop:max-w-[600px] mx-auto"
      titleCustomClass="normal-case  !text-charcoal-gray !text-h3"
      title={locale?.title}
      disableMobileGrow
    >
      <p className="text-med-gray font-medium">{locale?.description}</p>
    </Modal>
  );
};

export default NavigateBlockModal;
