import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { AUTH_LOGIN } from 'utilities/routes';
import { firebaseAuth } from '../firebase/config';

interface Props {
  children: ReactNode;
}

export const NotVerifiedEmailRoute = ({ children }: Props) => {
  const user = firebaseAuth.currentUser;

  const getContent = () => {
    if (!user) {
      return <Navigate to={AUTH_LOGIN} />;
    }
    return <>{children}</>;
  };

  return <>{getContent()}</>;
};
