import Toggle from 'components/ToggleComponent';
import { TimeSlotType } from 'hooks/useSignUpProviderSchedule.';
import { Dispatch } from 'react';
import { TimeSlotPicker } from './TimeSlotPicker';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';

export const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const MAX_TIME_SLOTS_NUMBER = 6;

const INITIAL_TIME_SLOT = {
  fromHours: '00',
  fromMinutes: '00',
  fromTimePeriod: 'AM',
  toHours: '00',
  toMinutes: '00',
  toTimePeriod: 'PM',
};

interface ISignUpProviderSchedule {
  schedule?: Array<Array<TimeSlotType>>;
  setSchedule?: Dispatch<React.SetStateAction<Array<Array<TimeSlotType>>>>;
}

export const SignUpProviderSchedule = ({
  schedule,
  setSchedule,
}: ISignUpProviderSchedule) => {
  const { data: locale } = useGetComponent({
    locale: 'en',
    componentId: componentIds.SIGN_UP_PROVIDER_MODULE,
  });

  const onToggleHandler = (checked: boolean, index: number) => {
    setSchedule?.((prev) => {
      const newState = [...prev];
      newState[index] = checked ? [INITIAL_TIME_SLOT] : [];
      return newState;
    });
  };

  const getIsChecked = (index: number) => {
    return !!schedule?.[index].length;
  };

  const onAddButtonToggle = (index: number) => {
    setSchedule?.((prev) => {
      const newState = [...prev];
      newState[index] =
        newState[index].length < MAX_TIME_SLOTS_NUMBER
          ? [...newState[index], INITIAL_TIME_SLOT]
          : newState[index];
      return newState;
    });
  };

  const onRemoveHandler = (dayIndex: number, slotIndex: number) => {
    setSchedule?.((prev) => {
      const newState = [...prev];

      newState[dayIndex].splice(slotIndex, 1);
      return newState;
    });
  };

  const onSlotChangeHandler = (
    dayIndex: number,
    slotIndex: number,
    param: keyof TimeSlotType,
    value: string,
  ) => {
    setSchedule?.((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));

      newState[dayIndex][slotIndex][param] = value;
      return newState;
    });
  };

  return (
    <div className="mt-[30px]">
      <h5 className="text-title font-semibold text-btn-black mb-5">
        {locale?.schedule?.workingHours}
      </h5>
      {schedule?.map((day, index) => {
        return (
          <div key={index} className="py-5 -ml-[5px] flex items-start">
            <div className="flex items-center w-[246px] mt-[9px]">
              <Toggle
                checked={getIsChecked(index)}
                labelLeft=""
                labelRight=""
                onChange={(checked) => onToggleHandler(checked, index)}
              />
              <span className="flex-grow ml-1 text-base font-semibold text-btn-black">
                {DAYS_OF_WEEK[index]}
              </span>
              {getIsChecked(index) ? (
                <span className="text-base font-medium text-bright-green">
                  {locale?.schedule?.available}
                </span>
              ) : (
                <span className="text-base font-medium text-med-gray-3">
                  {locale?.schedule?.unavailable}
                </span>
              )}
            </div>
            <div className="flex-grow flex flex-col gap-2.5 items-center">
              {day.map((item, j) => {
                return (
                  <TimeSlotPicker
                    slot={item}
                    onRemoveHandler={() => onRemoveHandler(index, j)}
                    onSlotChangeHandler={(
                      param: keyof TimeSlotType,
                      value: string,
                    ) => onSlotChangeHandler(index, j, param, value)}
                  />
                );
              })}
            </div>
            <button
              onClick={() => onAddButtonToggle(index)}
              className="w-6 mt-[3px] text-mobileH2 text-clc-blue font-bold"
            >
              +
            </button>
          </div>
        );
      })}
    </div>
  );
};
